import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Header, Icon, Image, Segment} from "semantic-ui-react";

import Review from "../Review";
import {gaEventAd} from "../../../api/GA";
import ThreeOptions from "../Surveys/ThreeOptions";
import {ReactComponent as DesertImage} from "../../../static/desert.svg";
import RakudaLogo from "../../../static/figures/rakuda/rakuda_transparent.png";
import RakudaBabyLogo from "../../../static/figures/rakuda/rakuda_baby@2x.png";
import * as ROUTES from "../../../constants/routes";
import {gaEventReview} from "../../../api/GA";

const Ad = ({alt, src, href, maxHeight}) => {
  const handleClick = () => {
    gaEventAd(href, alt);
  };

  return (
    <div style={{textAlign: "center"}}>
      <a
        href={href}
        target="_blank"
        onClick={handleClick}
        rel="noopener noreferrer"
      >
        <Image
          alt={alt}
          src={src}
          style={{
            cursor: "pointer",
            display: "inline-block",
            maxHeight: `${maxHeight}px`,
            borderRadius: "6px",
          }}
        />
      </a>
    </div>
  );
};

const Survey = (props) => {
  const {survey} = props;
  switch (survey.type) {
    case "three_options":
      return (
        <Segment fluid style={{padding: 15}}>
          <ThreeOptions survey={survey} />
        </Segment>
      );
    default:
      return null;
  }
};

const GuideFeed = () => {
  const navigate = useNavigate();
  const {univ_id} = useParams();
  return (
    <Segment
      fluid
      style={{
        padding: 15,
        fontFamily: "sans-serif",
        backgroundColor: "#f7f7f7",
        border: "none",
        color: "#333",
        overflow: "hidden",
      }}
    >
      <div style={{position: "relative"}}>
        <div style={{position: "relative", zIndex: 10}}>
          <Header
            as="h3"
            style={{fontFamily: "inherit", color: "inherit", margin: 0}}
          >
            授業を評価しよう！
          </Header>
          <p style={{fontFamily: "inherit", margin: "8px 0", color: "dimgray"}}>
            楽単らくだは、授業評価を投稿して他の学生と共有することができる大学生のための授業評価サイトです。
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="primary"
              style={{
                fontFamily: "inherit",
                margin: "8px 5% 8px 0",
              }}
              onClick={() => {
                gaEventReview("Click", "Review Link from GuideFeed Button");
                navigate(ROUTES.APP.POST.replace(":univ_id", univ_id));
              }}
            >
              <Icon name="pencil" />
              投稿する
            </Button>
          </div>
        </div>

        <DesertImage
          style={{
            position: "absolute",
            bottom: -12,
            left: 0,
            right: 0,
            margin: -15,
            zIndex: 0,
          }}
        />
      </div>
      <div style={{position: "absolute", left: "15%", bottom: 6, zIndex: 10}}>
        <img
          src={RakudaBabyLogo}
          style={{
            width: "28px",
            marginRight: "4px",
          }}
          alt="rakuda_logo"
        />
        <img
          src={RakudaLogo}
          style={{
            width: "68px",
            margin: "-4px",
          }}
          alt="rakuda_logo"
        />
      </div>
    </Segment>
  );
};

export const Feed = (props) => {
  const {type, data} = props;
  switch (type) {
    case "review":
      return <Review {...data} />;
    case "ad":
      return <Ad {...data} />;
    case "survey":
      return <Survey {...data} />;
    case "guide":
      return <GuideFeed {...data} />;
    default:
      return null;
  }
};

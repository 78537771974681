import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Auth } from "aws-amplify";
import { Container } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";

import Navbar from "../../organisms/Navbar/index";
import Footer from "../../organisms/Footer/index";
import Review from "../../organisms/Review/index";
import { LoadingContent } from "../../atoms/LoadingContent";

import * as ROUTES from "../../../constants/routes";
import ReviewApi from "../../../api/v2/ReviewApi";
import { convertReviewSchemaToReview } from "../../../api/v2/ApiUtils";

const ReviewPageTemplate = () => {
  const { review_id: reviewId } = useParams();
  const navigate = useNavigate();

  const [review, setReview] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchReview = async () => {
      try {
        const reviewData = await new ReviewApi().getReview(reviewId);
        setReview(convertReviewSchemaToReview(reviewData));
      } catch (err) {
        if (err.response && err.response.status === 404) {
          navigate(ROUTES.NOT_FOUND);
        } else {
          console.error(err);
        }
      }
    };

    const fetchCurrentUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setCurrentUser(user);
      } catch {
        setCurrentUser(null);
      }
    };

    fetchReview();
    fetchCurrentUser();
  }, [reviewId]);

  return (
    <>
      <Helmet>
        <title>楽単らくだ</title>
        <meta
          name="description"
          content="「楽単らくだ」は、履修選択に悩める大学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
        />
        <meta name="robots" content="index" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:url"
          content={`https://www.rakutan-rakuda.com${ROUTES.APP.REVIEW.SINGLE.replace(":review_id", reviewId)}`}
        />
        <meta
          property="og:image"
          content={`https://api.rakutan-rakuda.com/api/v2/ogp/reviews/${reviewId}`}
        />
        <meta
          name="twitter:image"
          content={`https://api.rakutan-rakuda.com/api/v2/ogp/reviews/${reviewId}`}
        />
      </Helmet>
      <Navbar
        style={{ marginBottom: "24px" }}
        subtitle={"授業評価"}
        show_pusher={false}
        show_control={false}
      />
      <Container>
        {review ? (
          <Review
            author={review.author}
            review={review.review}
            comments={review.comments}
            opening
            is_posted_by_user={
              currentUser
                ? currentUser.attributes.sub === review.author.user_id ||
                  (currentUser.signInUserSession.idToken.payload["cognito:groups"]?.includes("admin") ?? false)
                : false
            }
            limited={!currentUser}
          />
        ) : (
          <LoadingContent />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default ReviewPageTemplate;

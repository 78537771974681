import React, {useState} from "react";
import ReviewList from "./ReviewList";
import {Link, useNavigate} from "react-router-dom";

import * as CRITERION from "../../../constants/criterion";
import * as ROUTES from "../../../constants/routes";
import {ICON_TYPES} from "../../../constants/icon_types";
import RakudaIcon from "../../atoms/RakudaIcon";
import ReviewApi from "../../../api/v2/ReviewApi";
import {gaEventRegister} from "../../../api/GA";

import {shareReviewOnTwitter} from "../../../api/Sharing";

import {
  Dropdown,
  Segment,
  Placeholder,
  Label,
  Icon,
  Header,
  Button,
  Grid,
  Comment,
  Form,
  Divider,
} from "semantic-ui-react";

const ReplyComment = ({author, comment, lgtm, history}) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{cursor: "pointer"}}
        onClick={(e) => {
          e.stopPropagation();
          navigate(ROUTES.APP.USER.HOME.replace(":user_id", author.user_id));
        }}
      >
        <Comment style={{display: "flex"}}>
          <RakudaIcon
            bg_color={
              ICON_TYPES[author.icon_type] && ICON_TYPES[author.icon_type].color
            }
            style={{
              width: "35px",
              borderRadius: "10%",
              marginRight: "8px",
            }}
          />
          <Comment.Content>
            <Comment.Author>{author.user_name}</Comment.Author>
            <div style={{color: "gray"}}>@{author.screen_name}</div>
          </Comment.Content>
        </Comment>
      </div>
      <Comment.Text style={{marginLeft: "42px"}}>{comment}</Comment.Text>
      <div style={{textAlign: "right"}}>
        <Button icon basic size="tiny" style={{marginRight: "5px"}}>
          <Icon name="thumbs up outline" />
          {` ${lgtm}`}
        </Button>
      </div>
    </>
  );
};

const Review = ({
  author = {
    user_id: "xxxxxxxx",
    user_name: "ユーザ名",
    screen_name: "screen_name",
    univ_id: "nagoya_u",
    icon_type: "",
  },
  review = {
    review_id: "xxxxxxxx",
    course: "講義名",
    teacher: [{teacher_id: -1, name: "教員名"}],
    year: "2020",
    semester: "学期",
    course_group: "科目区分",
    comment: "コメント",
    criterion: {
      attendance_freq: CRITERION.ATTENDANCE_FREQ.ITEM.ALWAYS.key,
      scoring_method: CRITERION.SCORING_METHOD.ITEM.ONLY_REPORT.key,
      credit_easiness: CRITERION.CREDIT_EASINESS.ITEM.EASY.key,
      content_quality: CRITERION.CONTENT_QUALITY.ITEM.AVERAGE.key,
    },
    // lgtm: 0,
    // lgtmed: false,
    timestamp: 1680103136,
  },
  comments = [],
  is_posted_by_user = false,
  clickable = false, // レビューをクリックしたときに詳細ページに遷移する
  isStatic = false, // 表示のみにする

  loading = false,
  disabled = false, // メニュー・コメントを非表示にする
  limited = false, // レビューの表示を制限する
  history,
  ...otherProps
}) => {
  const navigate = useNavigate();
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);
  const [lgtm, setLgtm] = useState(review.lgtm ?? 0);
  const [lgtmed, setLgtmed] = useState(review.lgtmed ?? false);

  const anonymous =
    author.screen_name === "anonymous" ||
    author.user_name === "Guest" ||
    author.user_name === "Unknown";

  const handleLgtmClick = (e) => {
    e.stopPropagation();
    const reviewApi = new ReviewApi();
    if (lgtmed) {
      reviewApi
        .deleteReviewLgtm(review.review_id)
        .then((res) => {
          setLgtm(lgtm - 1);
          setLgtmed(false);
        })
        .catch((err) => {
          console.log(err);
          alert(
            "役に立った！を取り消せませんでした。一定時間をおいて再度お試しください。"
          );
        });
    } else {
      reviewApi
        .putReviewLgtm(review.review_id)
        .then((res) => {
          setLgtm(lgtm + 1);
          setLgtmed(true);
        })
        .catch((err) => {
          console.log(err);
          alert(
            "役に立った！できませんでした。一定時間をおいて再度お試しください。"
          );
        });
    }
  };

  const showTwitterSharingDialog = (course, comment) => {
    if (window.confirm("このレビューをTwitterで共有しますか？")) {
      shareReviewOnTwitter(course, comment);
    }
  };

  const deletePost = (review_id) => {
    if (!window.confirm("このレビューを削除しますか？")) return;
    const reviewApi = new ReviewApi();
    reviewApi
      .deleteReview(review_id)
      .then((res) => {
        alert("レビューを削除しました。");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        alert(
          "レビューを削除できませんでした。一定時間をおいて再度お試しください。"
        );
      });
  };

  if (loading) {
    return (
      <Segment
        className="Review"
        style={{minWidth: "320px", margin: "16px auto"}}
      >
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line length="short" />
            <Placeholder.Line length="long" />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
            <Placeholder.Line length="very long" />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line length="long" />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
    );
  } else {
    return (
      <div
        style={
          clickable
            ? {
                cursor: "pointer",
              }
            : null
        }
        onClick={
          clickable
            ? () => {
                navigate(
                  ROUTES.APP.REVIEW.SINGLE.replace(
                    ":review_id",
                    review.review_id
                  )
                );
              }
            : undefined
        }
      >
        <Segment
          className="Review"
          color={is_posted_by_user ? "grey" : null}
          style={{
            minWidth: "320px",
            margin: "16px auto",
          }}
          {...otherProps}
        >
          <Grid>
            <Grid.Column width={12}>
              <Comment.Group>
                <div
                  style={
                    isStatic || anonymous
                      ? {cursor: "default"}
                      : {cursor: "pointer"}
                  }
                  onClick={
                    !(isStatic || anonymous)
                      ? (e) => {
                          e.stopPropagation();
                          navigate(
                            ROUTES.APP.USER.HOME.replace(
                              ":user_id",
                              author.user_id
                            )
                          );
                        }
                      : (e) => e.stopPropagation()
                  }
                >
                  <Comment style={{display: "flex"}}>
                    <RakudaIcon
                      bg_color={
                        ICON_TYPES[author.icon_type] &&
                        ICON_TYPES[author.icon_type].color
                      }
                      style={{
                        width: "35px",
                        borderRadius: "10%",
                        marginRight: "8px",
                      }}
                    />
                    <Comment.Content>
                      <Comment.Author>
                        {anonymous ? "名もなきらくだ" : author.user_name}
                      </Comment.Author>
                      <div style={{color: "gray"}}>
                        @{anonymous ? "unknown" : author.screen_name}
                      </div>
                    </Comment.Content>
                  </Comment>
                </div>
              </Comment.Group>
            </Grid.Column>
            <Grid.Column width={4}>
              {disabled ? null : (
                <div
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "0px",
                  }}
                >
                  <Dropdown
                    style={{
                      boxShadow: "none",
                    }}
                    button
                    basic
                    floating
                    direction="left"
                    color="red"
                    icon="ellipsis horizontal"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        icon="twitter"
                        text="Twitterで共有する"
                        onClick={() =>
                          showTwitterSharingDialog(
                            review.course,
                            review.comment
                          )
                        }
                      />
                      {is_posted_by_user && (
                        <Dropdown.Item
                          icon="trash"
                          text="レビューを削除する"
                          onClick={() => deletePost(review.review_id)}
                        />
                      )}
                      {/* TODO: 投稿日時の表示を日本時間に修正 */}
                      <Dropdown.Item disabled>
                        {(() => {
                          var d = new Date(review.timestamp * 1000);
                          return (
                            <small>
                              {d.getFullYear()}年{d.getMonth() + 1}月
                              {d.getDate()}日
                              {/* {d.getHours()}:
                            {("00" + d.getMinutes()).slice(-2)} */}
                              に{is_posted_by_user && "あなたが"}
                              投稿
                            </small>
                          );
                        })()}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </Grid.Column>
          </Grid>
          <Header as="h4" style={{margin: "10px 0px 0px 0px"}}>
            <span
              style={!isStatic ? {cursor: "pointer"} : null}
              onClick={
                !isStatic
                  ? (e) => {
                      e.stopPropagation();
                      navigate(
                        ROUTES.APP.REVIEW.SEARCH.replace(
                          ":univ_id",
                          review.univ_id
                        ) + `?course=${review.course}`
                      );
                    }
                  : undefined
              }
            >
              {review.course}
            </span>
            <Header.Subheader>
              <span
                style={!isStatic ? {cursor: "pointer"} : null}
                onClick={
                  !isStatic
                    ? (e) => {
                        e.stopPropagation();
                        navigate(
                          ROUTES.APP.REVIEW.SEARCH.replace(
                            ":univ_id",
                            review.univ_id
                          ) +
                            `?teacher=${review.teacher
                              .map((teacher) => teacher.name)
                              .join("%2C")}` // 教員名をカンマ区切りに
                        );
                      }
                    : undefined
                }
              >
                {review.teacher.map((teacher) => teacher.name).join(" / ")}
                <Icon name="angle right" color="grey" inverted />
              </span>
              ／{review.year}
              &nbsp;
              {review.semester}／{review.course_group}
            </Header.Subheader>
          </Header>
          <p style={{padding: "0px"}}>
            {false && is_posted_by_user && (
              <Label attached="top right" color="green">
                あなたの投稿
                <Icon
                  name="delete"
                  onClick={() => deletePost(review.review_id)}
                />
              </Label>
            )}
            {limited ? review.comment.substr(0, 30) + "..." : review.comment}
            {limited && (
              <div
                style={{
                  marginTop: "6px",
                  color: "transparent",
                  textShadow: "0 0 8px rgba(0,0,0,0.5)",
                  position: "relative",
                }}
              >
                <p>
                  この授業は非常に興味深く、知識を深める良い機会でした。教授は情熱的で、わかりやすい講義を行ってくれました。
                  課題も適切な難易度で、自己成長につながりました。クラスメートとのディスカッションも活発で、異なる視点からの学びが得られました。
                  ただし、時折情報が多すぎることがありましたが、それでも非常に満足しています。
                </p>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      gaEventRegister("Review"); // GAイベント送信
                      navigate(ROUTES.APP.AUTH);
                    }}
                    color="instagram"
                    style={{
                      marginRight: "10px",
                      display: "block",
                      margin: "0 auto 8px",
                    }}
                  >
                    無料でユーザー登録する
                  </Button>
                  <Link
                    style={{
                      textShadow: "0 0 8px white",
                    }}
                    to={ROUTES.APP.AUTH}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    ログインはこちら
                  </Link>
                </div>
              </div>
            )}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {[
                CRITERION.ATTENDANCE_FREQ,
                CRITERION.SCORING_METHOD,
                CRITERION.CREDIT_EASINESS,
                CRITERION.CONTENT_QUALITY,
              ].map((item, key) => (
                <Label size="mini" image key={key}>
                  {item.label}
                  <Label.Detail>
                    {CRITERION.getLabel(
                      item,
                      review.criterion[item.key],
                      "label"
                    )}
                  </Label.Detail>
                </Label>
              ))}
            </div>
            {!disabled && (
              <div
                style={{
                  marginLeft: "16px",
                  marginRight: "5px",
                  textAlign: "right",
                  flexShrink: 0,
                }}
              >
                <Button
                  icon
                  basic={!lgtmed}
                  color={lgtmed ? "yellow" : undefined}
                  size="tiny"
                  disabled={limited}
                  onClick={handleLgtmClick}
                >
                  <Icon name={`thumbs up ${!lgtmed && "outline"}`} />{" "}
                  役に立った！
                  {` ${lgtm === 0 ? "" : lgtm}`}
                </Button>
              </div>
            )}
          </div>
          {!disabled && ( // TODO: コメント機能／falseをtrueにする
            <div>
              {false && comments.length > 0 && (
                <div style={{marginTop: "10px"}}>
                  <Grid>
                    {/* TODO: コメント機能 */}
                    <Grid.Column width={16}>
                      <div
                        style={{
                          margin: "8px 5px",
                          display: "inline-block",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({
                            isCommentsOpen: !isCommentsOpen,
                          });
                        }}
                      >
                        {comments.length}件のコメント
                        {isCommentsOpen ? (
                          <Icon name="angle down" />
                        ) : (
                          <Icon name="angle right" />
                        )}
                      </div>
                    </Grid.Column>
                  </Grid>
                </div>
              )}
              {/* TODO: コメント機能 */}
              {false && isCommentsOpen && (
                <div
                  style={{cursor: "default"}}
                  onClick={(e) => e.stopPropagation()}
                >
                  {comments.length > 0 && <Divider />}
                  <Comment.Group collapsed={false} style={{maxWidth: "9999px"}}>
                    {comments.map((comment, key) => (
                      <ReplyComment key={key} {...comment} history={history} />
                    ))}
                    <div style={{marginTop: "20px", textAlign: "center"}}>
                      {isCommentFormOpen ? (
                        <Form style={{textAlign: "right"}}>
                          <Form.TextArea placeholder="コメントを入力" />
                          <Button primary>投稿する</Button>
                        </Form>
                      ) : (
                        <div
                          style={{
                            display: "inline-block",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.setState({isCommentFormOpen: true});
                          }}
                        >
                          <Icon name="pencil alternate" />
                          コメントする
                        </div>
                      )}
                    </div>
                  </Comment.Group>
                </div>
              )}
            </div>
          )}
        </Segment>
      </div>
    );
  }
};

export default Review;

import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  Button,
  Header,
  Container,
  Grid,
  Form,
  Checkbox,
  Icon,
  Input,
  Dropdown,
} from "semantic-ui-react";
import Navbar from "../../organisms/Navbar/index";
import Footer from "../../organisms/Footer/index";
import * as ROUTES from "../../../constants/routes";
import UNIV from "../../../constants/univ";
import { NONE_MAJOR_OPTION } from "../../../constants/user";
import UserApi from "../../../api/v2/UserApi";
import RakudaIcon from "../../atoms/RakudaIcon";
import {
  ICON_TYPES,
  getLimitedIconTypeOptions,
} from "../../../constants/icon_types";
import CommunityApi from "../../../api/v2/CommunityApi";

const USER_NAME_LIMIT = 32;
const INTRODUCTION_LIMIT = 150;
const COMMUNITY_LIMIT = 100;

const univOptions = Object.keys(UNIV)
  .filter((key) => UNIV[key].status === "open") // 公開中の大学のみ取得
  .map((key) => ({ key, value: key, text: UNIV[key].name })); // optionに変換
// departmentOptionsはstateとしてunivOptionsの変更に応じて動的に変更する

const entranceYearOptions = (() => {
  const date = new Date();
  date.setMonth(date.getMonth() - 3); // 年度を取得
  const currentYear = date.getFullYear();
  const options = [];
  for (let i = 0; i < 6; i++) {
    const year = currentYear - i;
    options.push(
      { key: `${year}-b`, value: `${year}-b`, text: `${year}年度 学部入学` },
      {
        key: `${year - 4}-m`,
        value: `${year - 4}-m`,
        text: `${year}年度 大学院入学`,
      }
    );
  }
  return options;
})();

const gradeOptions = (() => {
  const date = new Date();
  date.setMonth(date.getMonth() - 3); // 年度を取得
  const currentYear = date.getFullYear();
  return [
    { key: currentYear, value: currentYear, text: "大学1年生" },
    { key: currentYear - 1, value: currentYear - 1, text: "大学2年生" },
    { key: currentYear - 2, value: currentYear - 2, text: "大学3年生" },
    { key: currentYear - 3, value: currentYear - 3, text: "大学4年生" },
    { key: currentYear - 4, value: currentYear - 4, text: "大学院1年生" },
    { key: currentYear - 5, value: currentYear - 5, text: "大学院2年生" },
  ];
})();

const iconOptions = getLimitedIconTypeOptions(0);

const careerPathOptions = [
  { key: false, value: false, text: "学部で就職予定" },
  { key: true, value: true, text: "大学院に進学予定／在学中" },
];

const createOption = (value) => ({ key: value, value, text: value });

const shuffleArray = (array) => {
  const cloneArray = [...array];
  return cloneArray.reduce((_, cur, idx) => {
    let rand = Math.floor(Math.random() * (idx + 1));
    cloneArray[idx] = cloneArray[rand];
    cloneArray[rand] = cur;
    return cloneArray;
  });
};

const RegisterPageTemplate = () => {
  const navigate = useNavigate();
  const timer = useRef(null);
  const [loading, setLoading] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [communityOptions, setCommunityOptions] = useState([]);
  const [communitySuggestions, setCommunitySuggestions] = useState([]);
  const [communityFetchLoading, setCommunityFetchLoading] = useState(false);
  const [form, setForm] = useState({
    user_name: { value: "", hasChecked: false, errorMessage: "" },
    univ_id: { value: "", hasChecked: false, errorMessage: "" },
    department: { value: "", hasChecked: false, errorMessage: "" },
    major: { value: "", hasChecked: false, errorMessage: "" },
    entrance_year: { value: "", hasChecked: false, errorMessage: "" }, // 入学年度
    grade: { value: "", hasChecked: false, errorMessage: "" }, // 現在の学年の入学年度
    icon_type: {
      value: Object.keys(ICON_TYPES).filter(
        (key) => ICON_TYPES[key].scoreLimit <= 0
      )[0], // スコアに応じて選択できるアイコンを制限
      hasChecked: true,
      errorMessage: "",
    },
    introduction: { value: "", hasChecked: true, errorMessage: "" },
    community: { value: "", hasChecked: true, errorMessage: "" },
    planning_grad_school: { value: false, hasChecked: true, errorMessage: "" },
    admit_mail: { value: true, hasChecked: true, errorMessage: "" },
  });

  const getDepartmentOptions = (univId) => {
    return Object.keys(UNIV[univId].department)
      .concat(["その他"])
      .map((department) => ({
        key: department,
        value: department,
        text: department,
      }));
  };

  const getMajorOptions = (univId, department) => {
    return UNIV[univId] && UNIV[univId].department[department]
      ? UNIV[univId].department[department].majors
          .map((major) => createOption(major))
          .concat([NONE_MAJOR_OPTION])
      : [NONE_MAJOR_OPTION];
  };

  const handleFormChange = (e, { name, value }) => {
    setForm((prevForm) => {
      const newForm = {
        ...prevForm,
        [name]: { ...prevForm[name], value, hasChecked: true },
      };
      if (name === "univ_id") {
        newForm.department.value = "";
        setDepartmentOptions(getDepartmentOptions(value));
        setCommunitySuggestions([]);
        // サジェスト用にコミュニティを取得
        const communityApi = new CommunityApi();
        communityApi.getUnivCommunities(value).then((res) => {
          // コミュニティを3つランダムに選択
          const communities = shuffleArray(res).slice(0, 3);
          setCommunitySuggestions(communities);
        });
      }
      return newForm;
    });
  };

  const handleCheckboxChange = (e, { name, checked }) => {
    setForm((prevForm) => ({
      ...prevForm,
      [name]: { ...prevForm[name], value: checked },
    }));
  };

  const validationCheck = () => {
    let isValid = true;
    setForm((prevForm) => {
      const updatedForm = { ...prevForm };
      Object.keys(updatedForm).forEach((key) => {
        updatedForm[key].errorMessage = "";
        // 未入力チェック
        if (!updatedForm[key].hasChecked) {
          updatedForm[key].errorMessage = "※未入力です";
          isValid = false;
          return;
        }
        // 入力内容チェック
        switch (key) {
          case "user_name":
            if (updatedForm[key].value.trim().length === 0) {
              updatedForm[key].errorMessage =
                "※1文字以上のユーザ名にしてください";
              isValid = false;
            }
            if (updatedForm[key].value.trim().length > USER_NAME_LIMIT) {
              updatedForm[
                key
              ].errorMessage = `※${USER_NAME_LIMIT}文字以内で入力してください`;
              isValid = false;
            }
            break;
          case "introduction":
            if (updatedForm[key].value.trim().length > INTRODUCTION_LIMIT) {
              updatedForm[
                key
              ].errorMessage = `※${INTRODUCTION_LIMIT}文字以内で入力してください`;
              isValid = false;
            }
            break;
          case "community":
            if (updatedForm[key].value.trim().length > COMMUNITY_LIMIT) {
              updatedForm[
                key
              ].errorMessage = `※${COMMUNITY_LIMIT}文字以内で入力してください`;
              isValid = false;
            }
            break;
          case "univ_id":
          case "department":
          case "major":
          case "entrance_year":
          case "grade":
          case "icon_type":
            if (updatedForm[key].value === "") {
              updatedForm[key].errorMessage = "※未入力です";
              isValid = false;
            }
            break;
          default:
            break;
        }
      });
      return updatedForm;
    });
    return isValid;
  };

  const handleSubmit = () => {
    setLoading(true);
    // バリデーションチェック
    if (!validationCheck()) {
      console.log("Validation Error");
      setLoading(false);
      return;
    }
    // データ取り出し
    const postForm = Object.keys(form).reduce((obj, key) => {
      obj[key] = form[key].value;
      return obj;
    }, {});
    // データ加工
    postForm.entrance_year = parseInt(
      form.entrance_year.value.split("-")[0],
      10
    );
    postForm.community = postForm.community.trim().replace(/\s+/g, " ");
    // ユーザ登録処理
    const userApi = new UserApi();
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const screenName = user.username;
        const postData = { ...postForm, screen_name: screenName };
        userApi
          .createUser(postData)
          .then(() => {
            // 所属する大学のトップページへ遷移
            navigate(ROUTES.APP.HOME.replace(":univ_id", postForm.univ_id));
          })
          .catch(() => {
            alert(
              "ユーザ情報の登録に失敗しました。時間をおいて再度お試しください。"
            );
          })
          .finally(() => setLoading(false));
      })
      .catch(() => {
        alert("ユーザ認証に失敗しました。時間をおいて再度お試しください。");
        setLoading(false);
      });
  };

  const onSelectChange = (e, { name, value }) => {
    switch (name) {
      case "community":
        value = value.trim().replace(/\s+/g, " ");
        handleFormChange(e, { name, value });
        setCommunityOptions([]);
        break;
      default:
        break;
    }
  };

  // 入力値が変化したときの処理
  const onSearchChange = (e, obj) => {
    const { name, searchQuery } = obj;
    const univId = form.univ_id.value;
    switch (name) {
      case "community":
        if (searchQuery.length === 0) {
          setCommunityOptions([]);
          setCommunityFetchLoading(false);
          break;
        }
        clearTimeout(timer.current);
        setCommunityFetchLoading(true);
        timer.current = setTimeout(() => {
          const communityApi = new CommunityApi();
          communityApi
            .getUnivCommunities(univId, searchQuery)
            .then((res) => {
              const currentValue = form.community.value;
              const communityOptions = res
                .map((community) => createOption(community.name))
                .filter((option) => option.value !== currentValue); // 現在の値と同じものはoptionが重複するので除外
              setCommunityOptions(communityOptions);
            })
            .finally(() => setCommunityFetchLoading(false));
        }, 1000); // 1秒間入力がなければAPIを叩く
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // 既にDBにユーザーが存在する場合はユーザーページへ遷移
        const userId = user.attributes.sub;
        const userApi = new UserApi();
        userApi.getUser(userId).then((user) => {
          navigate(ROUTES.APP.USER.HOME.replace(":user_id", user.user_id));
        });
      })
      .catch(() => {
        // ログインしていない場合はランディングページへ遷移
        navigate(ROUTES.LANDING);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <title>楽単らくだ</title>
        <meta
          name="description"
          content="「楽単らくだ」は、履修選択に悩める大学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
        />
        <meta name="robots" content="index" />
      </Helmet>
      <Navbar
        style={{ marginBottom: "24px" }}
        subtitle={"授業評価"}
        show_pusher={false}
        show_control={false}
        show_action={false}
      />
      <Container text>
        <Header as="h2">ユーザー登録</Header>
        <Form loading={loading}>
          <Form.Field required>
            <label>ユーザー名</label>
            <Input
              name="user_name"
              value={form.user_name.value}
              placeholder="らくたろう"
              onChange={handleFormChange}
            />
            <ErrorMessage message={form.user_name.errorMessage} />
            <p style={{ color: "gray", marginTop: "3px" }}>
              <small>ユーザー名は後から変更できます。</small>
            </p>
          </Form.Field>
          <Form.Select
            label="所属大学"
            name="univ_id"
            options={univOptions}
            value={form.univ_id.value}
            placeholder="-"
            required
            onChange={handleFormChange}
          />
          <ErrorMessage message={form.univ_id.errorMessage} />
          <Form.Select
            label="学部・研究科"
            name="department"
            options={departmentOptions}
            value={form.department.value}
            placeholder="-"
            required
            disabled={!form.univ_id.hasChecked}
            onChange={handleFormChange}
          />
          <ErrorMessage message={form.department.errorMessage} />
          <Form.Select
            label="学科・専攻"
            name="major"
            options={getMajorOptions(form.univ_id.value, form.department.value)}
            value={form.major.value}
            placeholder="-"
            required
            disabled={form.department.value === ""}
            onChange={handleFormChange}
          />
          <ErrorMessage message={form.major.errorMessage} />
          <Form.Group widths="equal">
            <Form.Field>
              <Form.Select
                label="入学年度"
                name="entrance_year"
                options={entranceYearOptions}
                value={form.entrance_year.value}
                placeholder="-"
                required
                onChange={handleFormChange}
              />
              <ErrorMessage message={form.entrance_year.errorMessage} />
            </Form.Field>
            <Form.Field>
              <Form.Select
                label="現在の学年"
                name="grade"
                options={gradeOptions}
                value={form.grade.value}
                placeholder="-"
                required
                onChange={handleFormChange}
              />
              <ErrorMessage message={form.grade.errorMessage} />
            </Form.Field>
          </Form.Group>
          <Grid>
            <Grid.Column width={11}>
              <Form.Select
                label="アイコン"
                name="icon_type"
                options={iconOptions}
                value={form.icon_type.value}
                onChange={handleFormChange}
              />
              <ErrorMessage message={form.icon_type.errorMessage} />
            </Grid.Column>
            <Grid.Column width={5}>
              <RakudaIcon
                bg_color={ICON_TYPES[form.icon_type.value]?.color}
                icon
                circular
                style={{ paddingTop: "10px", maxWidth: "150px" }}
              />
            </Grid.Column>
          </Grid>
          <Form.TextArea
            label="自己紹介"
            name="introduction"
            value={form.introduction.value}
            placeholder="自己紹介を入力"
            onChange={handleFormChange}
          />
          <ErrorMessage message={form.introduction.errorMessage} />
          <Form.Field>
            <label>所属サークル・部活</label>
            <Dropdown
              name="community"
              options={[
                ...(form.community.value !== ""
                  ? [createOption(form.community.value)]
                  : []), // 現在の値が""の場合はoptionを追加しない
                ...communityOptions,
              ]}
              value={form.community.value}
              placeholder="野球サークルらくだーず"
              loading={communityFetchLoading}
              disabled={!form.univ_id.hasChecked}
              fluid
              search
              selection
              clearable
              scrolling
              closeOnChange
              allowAdditions
              additionLabel="サークルまたは部活を追加: "
              noResultsMessage="所属サークルまたは部活を入力してください"
              icon={<Icon name="search" />}
              onSearchChange={onSearchChange}
              onChange={onSelectChange}
            />
            <ErrorMessage message={form.community.errorMessage} />
            {communitySuggestions.length > 0 && (
              <p style={{ marginTop: "3px", color: "gray" }}>
                <small>
                  <span style={{ paddingRight: "4px" }}>
                    あなたはもしかして...
                  </span>
                  {communitySuggestions.map((community, index) => (
                    <span key={index}>
                      {index === 0 ? "" : " / "}
                      {/* eslint-disable-next-line */}
                      <a
                        onClick={() => {
                          handleFormChange(null, {
                            name: "community",
                            value: community.name,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {community.name}
                      </a>
                    </span>
                  ))}
                </small>
              </p>
            )}
          </Form.Field>
          <Form.Select
            label="進路"
            name="planning_grad_school"
            options={careerPathOptions}
            value={form.planning_grad_school.value}
            placeholder="-"
            required
            onChange={handleFormChange}
          />
          <ErrorMessage message={form.planning_grad_school.errorMessage} />
          <Form.Field>
            <Checkbox
              label="学生生活お役立ちメールを受け取る"
              name="admit_mail"
              checked={form.admit_mail.value}
              onChange={handleCheckboxChange}
            />
            <ErrorMessage message={form.admit_mail.errorMessage} />
            <p style={{ color: "gray", fontSize: "0.9rem" }}>
              楽単らくだから学生生活に役立つ情報を受け取ることができます
            </p>
          </Form.Field>
          <div style={{ margin: "30px 0px 40px", textAlign: "center" }}>
            <p style={{ fontSize: "0.9rem" }}>
              ユーザー登録には、<Link to={ROUTES.TERMS}>利用規約</Link> および{" "}
              <Link to={ROUTES.PRIVACY}>プライバシーポリシー</Link>
              への同意が必要です。
            </p>
            <Button primary content="登録する" onClick={handleSubmit} />
          </div>
        </Form>
      </Container>
      <Footer />
    </>
  );
};

export default RegisterPageTemplate;

const ErrorMessage = ({ message }) => {
  return (
    message && <p style={{ color: "red", fontSize: "0.9rem" }}>{message}</p>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { Accordion, Form, Icon, Dropdown } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";

import * as ROUTES from "../../../constants/routes";
import CourseApi from "../../../api/v2/CourseApi";
import TeacherApi from "../../../api/v2/TeacherApi";
import UNIV from "../../../constants/univ";

const Searchbox = (props) => {
  const { univ_id = "", showDetail = false } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const typingTimerRef = useRef(null);
  const [isDetailActive, setIsDetailActive] = useState(false);
  const createOption = (value) => ({ key: value, text: value, value: value });
  const [values, setValues] = useState({
    keyword: [],
    teacher: [],
    course: "",
    course_group: "",
    semester: "",
  });

  const [options, setOptions] = useState({
    selected: {
      // 選択した選択肢を格納する
      keyword: [],
      teacher: [],
      course: "",
    },
    results: {
      // DBから取得した選択肢を格納する
      keyword: [],
      teacher: [],
      course: [],
      course_group: UNIV[univ_id].course_group.map(createOption),
      semester: UNIV[univ_id].periods.map(createOption),
    },
  });

  const [loading, setLoading] = useState({
    keyword: false,
    teacher: false,
    course: false,
    course_group: false,
    semester: false,
  });

  // クエリパラメータで渡ってきた値をセット
  const setQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const queries = {
      keyword: params.get("keyword")?.split(",") || [],
      teacher: params.get("teacher")?.split(",") || [],
      course: params.get("course") || "",
      course_group: params.get("course_group") || "",
      semester: params.get("semester") || "",
    };

    setValues(queries);

    setOptions((prevOptions) => ({
      ...prevOptions,
      selected: {
        ...prevOptions.selected,
        keyword: queries.keyword.map(createOption),
        teacher: queries.teacher.map(createOption),
        course: createOption(queries.course),
      },
    }));

    // 詳細検索が有効なとき
    if (
      queries.teacher.length > 0 ||
      queries.course ||
      queries.course_group ||
      queries.semester
    ) {
      setIsDetailActive(true);
    }
  };

  useEffect(() => {
    setQueryParams();
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  // 入力値が変化したときの処理
  const onSearchChange = (e, { name, searchQuery }) => {
    switch (name) {
      case "keyword":
        if (searchQuery.length === 0) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            results: { ...prevOptions.results, [name]: [] },
          }));
          setLoading((prevLoading) => ({ ...prevLoading, [name]: false }));
          break;
        }
        clearTimeout(typingTimerRef.current);
        setLoading((prevLoading) => ({ ...prevLoading, [name]: true }));
        typingTimerRef.current = setTimeout(() => {
          const courseApi = new CourseApi();
          const teacherApi = new TeacherApi();
          const currentValue = searchQuery;
          const newOptions = [];
          // 講義名で検索
          courseApi
            .getUnivCourses(univ_id, currentValue)
            .then((res) => {
              const courses = res.map((course) => course.name);
              const courseOptions = Array.from(new Set(courses))
                .map(createOption)
                .filter((option) => option.value !== currentValue); // 現在の値と同じものはoptionが重複するので除外
              newOptions.push(...courseOptions);
              // 教員名で検索
              teacherApi.getUnivTeachers(univ_id, currentValue).then((res) => {
                const teachers = res.map((teacher) => teacher.name);
                const teacherOptions = Array.from(new Set(teachers))
                  .map(createOption)
                  .filter((option) => option.value !== currentValue); // 現在の値と同じものはoptionが重複するので除外
                newOptions.push(...teacherOptions);
                // resultsを更新
                setOptions((prevOptions) => ({
                  ...prevOptions,
                  results: { ...prevOptions.results, [name]: newOptions },
                }));
              });
            })
            .finally(() => {
              setLoading((prevLoading) => ({ ...prevLoading, [name]: false }));
            });
        }, 1000); // 1秒間入力がなければAPIを叩く
        break;
      case "teacher":
        if (searchQuery.length === 0) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            results: { ...prevOptions.results, [name]: [] },
          }));
          setLoading((prevLoading) => ({ ...prevLoading, [name]: false }));
          break;
        }
        clearTimeout(typingTimerRef.current);
        setLoading((prevLoading) => ({ ...prevLoading, [name]: true }));
        typingTimerRef.current = setTimeout(() => {
          const teacherApi = new TeacherApi();
          const currentValue = [...values.teacher, searchQuery];
          // 教員名を検索してオートコンプリート
          teacherApi
            .getUnivTeachers(univ_id, searchQuery)
            .then((res) => {
              const teachers = res.map((teacher) => teacher.name);
              const teacherOptions = Array.from(new Set(teachers))
                .map(createOption)
                .filter((option) => !currentValue.includes(option.value)); // 現在の値と同じものはoptionが重複するので除外
              setOptions((prevOptions) => ({
                ...prevOptions,
                results: { ...prevOptions.results, [name]: teacherOptions },
              }));
            })
            .finally(() => {
              setLoading((prevLoading) => ({ ...prevLoading, [name]: false }));
            });
        }, 1000); // 1秒間入力がなければAPIを叩く
        break;
      case "course":
        if (searchQuery.length === 0) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            results: { ...prevOptions.results, [name]: [] },
          }));
          setLoading((prevLoading) => ({ ...prevLoading, [name]: false }));
          break;
        }
        clearTimeout(typingTimerRef.current);
        setLoading((prevLoading) => ({ ...prevLoading, [name]: true }));
        typingTimerRef.current = setTimeout(() => {
          const courseApi = new CourseApi();
          courseApi
            .getUnivCourses(univ_id, searchQuery)
            .then((res) => {
              const currentValue = values.course;
              const courses = res.map((course) => course.name);
              const courseOptions = Array.from(new Set(courses)) // 重複を削除
                .map(createOption)
                .filter((option) => option.value !== currentValue); // 現在の値と同じものはoptionが重複するので除外
              setOptions((prevOptions) => ({
                ...prevOptions,
                results: { ...prevOptions.results, [name]: courseOptions },
              }));
            })
            .finally(() => {
              setLoading((prevLoading) => ({ ...prevLoading, [name]: false }));
            });
        }, 1000); // 1秒間入力がなければAPIを叩く
        break;
      default:
        break;
    }
  };

  // 選択した値が変化したときの処理
  const onFormChange = (e, { name, value }) => {
    switch (name) {
      case "keyword":
        setOptions((prevOptions) => ({
          selected: {
            ...prevOptions.selected,
            [name]: value.map(createOption),
          },
          results: { ...prevOptions.results, [name]: [] },
        }));
        break;
      case "teacher":
        if (value.length > 0 && typeof value[value.length - 1] === "object") {
          // valueが文字列ではなく配列になっているoptionを選択したとき
          value = value[value.length - 1];
        }
        setOptions((prevOptions) => ({
          selected: {
            ...prevOptions.selected,
            [name]: value.map(createOption),
          },
          results: { ...prevOptions.results, [name]: [] },
        }));
        break;
      case "course":
        setOptions((prevOptions) => ({
          selected: {
            ...prevOptions.selected,
            [name]: createOption(value),
          },
          results: { ...prevOptions.results, [name]: [] },
        }));
        break;
      default:
        break;
    }

    // クエリパラメータを更新
    const queryParams = new URLSearchParams();
    const newValues = { ...values, [name]: value };
    // 空文字ならクエリパラメータに追加しない
    if (newValues.keyword.length > 0)
      queryParams.append("keyword", newValues.keyword.join(","));
    if (newValues.teacher.length > 0)
      queryParams.append("teacher", newValues.teacher.join(","));
    if (newValues.course) queryParams.append("course", newValues.course);
    if (newValues.course_group)
      queryParams.append("course_group", newValues.course_group);
    if (newValues.semester) queryParams.append("semester", newValues.semester);

    const search = queryParams.toString() ? `?${queryParams.toString()}` : "";
    navigate(
      `${ROUTES.APP.REVIEW.SEARCH.replace(":univ_id", univ_id)}${search}`
    );
  };

  const handleDetailClick = () => {
    setIsDetailActive((prev) => !prev);
  };

  return (
    <>
      <Form>
        <Form.Field>
          <Dropdown
            name="keyword"
            options={[...options.selected.keyword, ...options.results.keyword]}
            value={values.keyword}
            placeholder="例: 田中 太郎, 基礎セミナー"
            loading={loading.keyword}
            fluid
            multiple
            search
            selection
            clearable
            scrolling
            closeOnChange
            allowAdditions
            additionLabel="検索条件に追加: "
            noResultsMessage="検索キーワードを入力してください"
            icon={
              <Icon name="search" style={{ transform: "translate(0, -3px)" }} />
            }
            onSearchChange={onSearchChange}
            onChange={onFormChange}
          />
        </Form.Field>
      </Form>
      {showDetail && (
        <Accordion>
          <Accordion.Title
            active={isDetailActive}
            onClick={handleDetailClick}
            style={{
              textAlign: "center",
              color: "gray",
              margin: "10px 0px -2px",
              padding: "0px",
            }}
          >
            <Icon name="sliders horizontal" /> 詳細検索
            <Icon name={isDetailActive ? "angle up" : "angle down"} />
          </Accordion.Title>
          <Accordion.Content active={isDetailActive}>
            <Form style={{ margin: "10px 0px" }}>
              <Form.Dropdown
                label="教員名"
                name="teacher"
                options={[
                  ...options.selected.teacher,
                  ...options.results.teacher,
                ]}
                value={values.teacher}
                placeholder="田中 太郎"
                loading={loading.teacher}
                fluid
                multiple
                search
                selection
                clearable
                scrolling
                closeOnChange
                allowAdditions
                additionLabel="検索条件に追加: "
                noResultsMessage="検索キーワードを入力してください"
                icon={
                  <Icon
                    name="search"
                    style={{ transform: "translate(0, -3px)" }}
                  />
                }
                onSearchChange={onSearchChange}
                onChange={onFormChange}
              />
              <Form.Dropdown
                label="講義名"
                name="course"
                options={[
                  ...(values.course !== "" ? [options.selected.course] : []),
                  ...options.results.course,
                ]}
                value={values.course}
                placeholder="物理学基礎"
                loading={loading.course}
                fluid
                search
                selection
                clearable
                scrolling
                closeOnChange
                allowAdditions
                additionLabel="検索条件に追加: "
                noResultsMessage="検索キーワードを入力してください"
                icon={
                  <Icon
                    name="search"
                    style={{ transform: "translate(0, -3px)" }}
                  />
                }
                onSearchChange={onSearchChange}
                onChange={onFormChange}
              />
              <Form.Select
                label="科目区分"
                name="course_group"
                options={options.results.course_group}
                value={values.course_group}
                placeholder="理系基礎科目"
                loading={loading.course_group}
                clearable
                onChange={onFormChange}
              />
              <Form.Select
                label="開講学期"
                name="semester"
                options={options.results.semester}
                value={values.semester}
                placeholder="前期"
                loading={loading.semester}
                clearable
                onChange={onFormChange}
              />
            </Form>
          </Accordion.Content>
        </Accordion>
      )}
    </>
  );
};

export default Searchbox;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Button,
  Container,
  Grid,
  Form,
  Header,
  Progress,
  Segment,
} from "semantic-ui-react";
import { FaTrophy, FaTable } from "react-icons/fa";

import UNIV from "../../../constants/univ";
import * as ROUTES from "../../../constants/routes";
import Navbar from "../../organisms/Navbar/index";
import Footer from "../../organisms/Footer/index";
import Landing from "../../organisms/Landing/index";
import Searchbox from "../../molecules/Searchbox/index";
import ReviewApi from "../../../api/v2/ReviewApi";
import ApplicantApi from "../../../api/v2/ApplicantApi";
import NewsApi from "../../../api/v2/NewsApi";
import { gaEventTimeTable, gaPageView } from "../../../api/GA";
import { convertReviewSchemaToReview } from "../../../api/v2/ApiUtils";
import { FeedList } from "../../organisms/feeds/FeedList";
import { SymbolButton } from "../../atoms/buttons/SymbolButton";
import ReviewUserRanking from "../../organisms/ReviewUserRanking";

const AppHomeTemplate = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { univ_id = "" } = useParams();
  const [applicantCount, setApplicantCount] = useState(0);
  const [loadingApplicantCount, setLoadingApplicantCount] = useState(true);
  // const [reviewCount, setReviewCount] = useState(0);
  // const [loadingReviewCount, setLoadingReviewCount] = useState(true);
  const [news, setNews] = useState([]);
  const [newPosts, setNewPosts] = useState([]);

  const getApplicantCount = (univ_id) => {
    const applicantApi = new ApplicantApi();
    applicantApi
      .getUnivApplicantsCount(univ_id)
      .then((res) => {
        setApplicantCount(parseInt(res.count));
        setLoadingApplicantCount(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getReviewCount = (univ_id) => {
  //   const reviewApi = new ReviewApi();
  //   reviewApi
  //     .getUnivReviewsCount(univ_id)
  //     .then((count) => {
  //       setReviewCount(parseInt(count));
  //       setLoadingReviewCount(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const getGoalReviewCount = (reviewCount) => {
  //   // 目標値: 3, 10, 30, 100, 300, 1000, 3000, 10000, ...
  //   const digits = String(reviewCount).length;
  //   const offset = 10 ** (digits - 1);
  //   if (reviewCount < 3 * offset) {
  //     return 3 * offset;
  //   } else {
  //     return 10 * offset;
  //   }
  // };

  useEffect(() => {
    gaPageView("/app/" + univ_id);
    getApplicantCount(univ_id);
    // getReviewCount(univ_id);

    // お知らせを取得
    const newsApi = new NewsApi();
    newsApi.getNews().then((news) => {
      const filteredNews = news.filter(
        (item) =>
          item.limited_to.length === 0 || item.limited_to.includes(univ_id) // 非限定公開 or 公開対象なら表示
      );
      setNews(filteredNews);
    });

    // 新着投稿を取得
    const newPostLimit = 3; // 新着投稿の表示上限数
    const reviewApi = new ReviewApi();
    reviewApi.getUnivReviews(univ_id).then((reviews) => {
      setNewPosts(
        reviews
          .slice(0, newPostLimit)
          .map((review) => convertReviewSchemaToReview(review))
      );
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isStatusOpen = UNIV[univ_id].status
    ? UNIV[univ_id].status === "open"
    : false;

  return (
    <div>
      <Helmet>
        <title>楽単らくだ for {UNIV[univ_id].name}</title>
        <meta
          name="description"
          content={
            "「楽単らくだ」は、履修選択に悩める" +
            UNIV[univ_id].name +
            "の学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
          }
        />
        <meta name="robots" content="index" />
      </Helmet>
      <Navbar
        style={{ marginBottom: "24px" }}
        univ_id={univ_id}
        subtitle={"for " + UNIV[univ_id].name}
        show_pusher={true}
        show_control={true}
        show_action={isStatusOpen}
        control={
          isStatusOpen ? (
            <Form>
              <Form.Field>
                <Searchbox
                  univ_id={univ_id}
                  location={location}
                  pushHistory={navigate}
                  showDetail
                />
              </Form.Field>
            </Form>
          ) : applicantCount >= 200 ? (
            <div style={{ textAlign: "center" }}>
              <Header as="h2">
                近日公開予定
                <Header.Subheader style={{ marginTop: "4px" }}>
                  「楽単らくだ for {UNIV[univ_id].name}」は近日公開予定です。
                </Header.Subheader>
              </Header>
              <div style={{ marginBottom: "32px" }}>
                <Progress percent={applicantCount / 200} progress indicating>
                  {applicantCount} 件の評価
                </Progress>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Header as="h2">
                利用者募集中
                <Header.Subheader>
                  200件以上の評価が集まると 「楽単らくだ for{" "}
                  {UNIV[univ_id].name}」が公開されます。
                </Header.Subheader>
              </Header>
              {!loadingApplicantCount && applicantCount >= 0 && (
                <div style={{ marginBottom: "32px" }}>
                  <Progress percent={applicantCount / 200} progress indicating>
                    {applicantCount} 件の評価
                  </Progress>
                </div>
              )}
              <Button
                primary
                onClick={() => {
                  window.location.href = "https://forms.gle/pJaWWYiMDBy3srGJ8";
                }}
              >
                投稿する
              </Button>
            </div>
          )
        }
      />
      <Container text style={{ textAlign: "center" }}>
        {news.length > 0 && (
          <table
            style={{ margin: "0px auto", maxWidth: "400px" }}
            cellSpacing={2}
            cellPadding={0}
          >
            <tbody>
              {news.map((item) => {
                const date = new Date(item.date);
                const formattedDate = `${date.getFullYear()}.${
                  date.getMonth() + 1
                }.${date.getDate()}`;
                return (
                  <tr
                    style={{ lineHeight: "1", textAlign: "left" }}
                    key={item.id}
                  >
                    <td
                      style={{
                        paddingRight: "14px",
                        verticalAlign: "top",
                        color: "gray",
                      }}
                    >
                      <small>{formattedDate}</small>
                    </td>
                    <td>
                      <small
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      ></small>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Grid
          columns={2}
          centered
          style={{ margin: "0px auto", maxWidth: "400px" }}
        >
          <Grid.Column style={{ textAlign: "center", paddingLeft: 0 }}>
            <SymbolButton
              icon={<FaTrophy size={30} color="gray" />}
              label="講義ランキング"
              onClick={() => {
                gaEventTimeTable("HomePanel");
                navigate(
                  ROUTES.APP.RANKING.COURSE.replace(":univ_id", univ_id)
                );
              }}
              disabled={univ_id !== "nagoya_u"}
            />
          </Grid.Column>
          <Grid.Column style={{ textAlign: "center", paddingRight: 0 }}>
            <SymbolButton
              icon={<FaTable size={30} color="gray" />}
              label="時間割自動作成"
              onClick={() => {
                gaEventTimeTable("HomePanel");
                navigate(
                  ROUTES.APP.TIMETABLE.ROOT.replace(":univ_id", univ_id)
                );
              }}
            />
          </Grid.Column>
        </Grid>
        <div style={{ margin: "6px auto 32px", maxWidth: "400px" }}>
          <Segment>
            <ReviewUserRanking />
          </Segment>
        </div>
      </Container>
      {/* {!loadingReviewCount && isStatusOpen && (
        <Container text>
          <Progress
            percent={
              Math.round(
                (reviewCount / getGoalReviewCount(reviewCount)) * 100 * 10
              ) / 10
            }
            progress
            indicating
          >
            現在のレビュー数{reviewCount}件／目標
            {getGoalReviewCount(reviewCount)}件
          </Progress>
        </Container>
      )} */}
      <Landing univ_id={univ_id}>
        {newPosts.length > 0 && (
          <Container text>
            <Header
              as="h1"
              textAlign="center"
              style={{ margin: "48px 0px 24px" }}
            >
              新着投稿
              <Header.Subheader>
                楽単らくだには、日々多くの学生からの授業情報が寄せられています。
              </Header.Subheader>
            </Header>
            <FeedList
              feeds={newPosts.map((item) => {
                return { type: "review", data: item };
              })}
              disabled
              limited={false}
            />
          </Container>
        )}
      </Landing>
      <Footer univ_id={univ_id} />
    </div>
  );
};

export default AppHomeTemplate;

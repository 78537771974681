import { shuffleArray } from "./Array";

export class FeedMixer {
  getMixedFeeds = (
    { reviewFeeds = [], adFeeds = [], surveyFeeds = [] },
    insertInterval = 5
  ) => {
    let insertFeeds = shuffleArray([...surveyFeeds, ...adFeeds]);
    let mixedFeeds = [];

    for (let i = 0; i < reviewFeeds.length; i++) {
      mixedFeeds.push(reviewFeeds[i]);
      // インターバルごとにinsertFeedsを挿入
      if ((i + 1) % insertInterval === 0) {
        if (insertFeeds.length > 0) {
          mixedFeeds.push(insertFeeds.shift());
        }
      }
    }

    // ガイドを3件目に挿入
    const guideFeed = {
      type: "guide",
      data: {},
    };
    mixedFeeds.splice(2, 0, guideFeed); // レビューが2件未満の場合は末尾に追加

    // insertFeedsが残っていたら末尾に追加
    if (insertFeeds.length > 0) {
      insertFeeds = insertFeeds.filter(
        (insertFeed) => !surveyFeeds.includes(insertFeed)
      );
      insertFeeds.map((insertFeed) => {
        mixedFeeds.push(insertFeed);
      });
    }

    return mixedFeeds;
  };
}

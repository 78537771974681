import React, { useEffect, useState } from "react";
import {
  Link,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Auth } from "aws-amplify";
import { Helmet } from "react-helmet";
import {
  Card,
  Container,
  Dropdown,
  Form,
  Header,
  Icon,
  Button,
  Segment,
} from "semantic-ui-react";

import UNIV from "../../../../constants/univ";
import * as ROUTES from "../../../../constants/routes";
import Olive from "../../../../static/olive.png";
import Navbar from "../../../organisms/Navbar/index";
import Footer from "../../../organisms/Footer/index";
import { LoadingContent } from "../../../atoms/LoadingContent";
import {
  courseRanking,
  dummyCourses,
} from "../../../../static/ranking/course/ranking";
import { gaEventRegister } from "../../../../api/GA";

const CourseRankingPageTemplate = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const { univ_id = "" } = useParams();
  const courses = courseRanking[univ_id];
  const course_groups = Object.keys(courses);
  const params = new URLSearchParams(location.search);
  const [control, setControl] = useState({
    course_group: params.get("course_group") || course_groups[0] || "科目区分",
    semester: params.get("semester") || "全学期",
  });
  const ALL_SEMESTER = "全学期";

  const createOption = (val) => {
    return {
      key: val,
      text: val,
      value: val,
    };
  };

  const handleControlChange = (e, { name, value }) => {
    setControl((prev) => ({
      ...prev,
      [name]: value,
    }));
    // クエリパラメータを更新
    const queryParams = new URLSearchParams();
    queryParams.append("course_group", control.course_group);
    queryParams.append("semester", control.semester);
    const search = `?${queryParams.toString()}`;
    navigate(
      `${ROUTES.APP.RANKING.COURSE.replace(":univ_id", univ_id)}${search}`
    );
  };

  useEffect(() => {
    setLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setCurrentUser(user);
      })
      .catch(() => null)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>楽単らくだ for {UNIV[univ_id].name}</title>
        <meta
          name="description"
          content={
            "「楽単らくだ」は、履修選択に悩める" +
            UNIV[univ_id].name +
            "の学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
          }
        />
        <meta name="robots" content="index" />
      </Helmet>
      <Navbar
        style={{ marginBottom: "24px" }}
        univ_id={univ_id}
        subtitle={"for " + UNIV[univ_id].name}
        show_pusher={false}
        show_control={false}
      />
      <Container text>
        {loading ? (
          <LoadingContent />
        ) : (
          <div
            style={{
              padding: "24px 0px",
              textAlign: "center",
            }}
          >
            <Header as="h1">
              <img
                src={Olive}
                alt="olive"
                style={{
                  width: "28px",
                  height: "auto",
                  transform: "rotate(-24deg)",
                  pointerEvents: "none",
                  userSelect: "none",
                }}
              />
              らくだAIランキング
              <img
                src={Olive}
                alt="olive"
                style={{
                  width: "28px",
                  height: "auto",
                  transform: "rotate(24deg) scaleX(-1)",
                  pointerEvents: "none",
                  userSelect: "none",
                }}
              />
              <Header.Subheader>
                <span style={{ display: "inline-block" }}>
                  授業評価をもとにらくだ独自のAIが
                </span>
                <span style={{ display: "inline-block" }}>
                  ランキングを作成します
                </span>
              </Header.Subheader>
            </Header>
            {currentUser ? (
              <div style={{ marginTop: "32px" }}>
                <div style={{ paddingBottom: "32px", textAlign: "right" }}>
                  <Form>
                    <Dropdown
                      name="course_group"
                      selection
                      placeholder="科目区分"
                      value={control.course_group}
                      onChange={handleControlChange}
                      options={course_groups.map(createOption)}
                      style={{ marginRight: "8px" }}
                    />
                    <Dropdown
                      name="semester"
                      selection
                      placeholder="開講学期"
                      value={control.semester}
                      onChange={handleControlChange}
                      compact
                      options={[createOption(ALL_SEMESTER)].concat(
                        UNIV[univ_id].periods.map(createOption)
                      )}
                    />
                  </Form>
                </div>
                <CourseCardList
                  univ_id={univ_id}
                  courses={courses[control.course_group].filter((c) =>
                    control.semester === ALL_SEMESTER
                      ? true
                      : c.semester === control.semester
                  )}
                />
              </div>
            ) : (
              <div style={{ marginTop: "32px" }}>
                <div style={{ paddingBottom: "32px", textAlign: "right" }}>
                  <Form>
                    <Dropdown
                      selection
                      value="科目区分"
                      options={[createOption("科目区分")]}
                      style={{ marginRight: "8px" }}
                      disabled
                    />
                    <Dropdown
                      selection
                      value="学期"
                      options={[createOption("学期")]}
                      compact
                      disabled
                    />
                  </Form>
                </div>
                <CourseCardList
                  limited
                  univ_id={univ_id}
                  courses={dummyCourses}
                />
              </div>
            )}
          </div>
        )}
      </Container>
      <Footer univ_id={univ_id} />
    </>
  );
};

export default CourseRankingPageTemplate;

const CourseCardList = (props) => {
  const { univ_id, courses, limited = false } = props;
  const navigate = useNavigate();

  return (
    <Card.Group
      centered
      textAlign="left"
      style={{
        position: "relative",
      }}
    >
      {courses.map((course, i) => {
        const { course_name, teachers, semester, course_group } = course;

        const iconType = i < 3 ? "crown" : "normal";
        let iconColor = "";
        if (i === 0) {
          iconColor = "#e0ad15";
        } else if (i === 1) {
          iconColor = "silver";
        } else if (i === 2) {
          iconColor = "saddlebrown";
        } else {
          iconColor = "#1E6873";
        }

        return (
          <CourseCard
            key={i}
            course={course_name}
            teachers={teachers}
            semester={semester}
            course_group={course_group}
            href={
              ROUTES.APP.REVIEW.SEARCH.replace(":univ_id", univ_id) +
              `?teacher=${teachers.join("%2C")}` +
              `&course=${course_name}` +
              `&course_group=${course_group}` +
              `&semester=${semester}`
            }
            iconType={iconType}
            iconColor={iconColor}
            index={i + 1}
            limited={limited}
          />
        );
      })}
      {limited && (
        <Segment.Group
          raised
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: "80%",
            margin: "0px",
            padding: "16px 24px",
            backgroundColor: "white",
          }}
        >
          <Segment>
            <p style={{ color: "gray" }}>
              <span style={{ display: "inline-block" }}>
                無料のユーザー登録で
              </span>
              <span style={{ display: "inline-block" }}>
                レビューやランキングが見放題！
              </span>
            </p>
          </Segment>
          <Segment>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                gaEventRegister("Ranking"); // GAイベント送信
                navigate(ROUTES.APP.AUTH);
              }}
              color="instagram"
              style={{
                marginRight: "10px",
                display: "block",
                margin: "12px auto 8px",
              }}
            >
              無料でユーザー登録
            </Button>
            <Link
              style={{
                textShadow: "0 0 8px white",
              }}
              to={ROUTES.APP.AUTH}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              ログインはこちら
            </Link>
          </Segment>
        </Segment.Group>
      )}
    </Card.Group>
  );
};

const CourseCard = (props) => {
  const {
    course = "",
    teachers = [],
    semester = "",
    course_group = "",
    href = "#",
    iconType = "normal",
    iconColor = "gray",
    index = 0,
    limited = false,
  } = props;
  return (
    <LinkCard href={limited ? "" : href}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ userSelect: "none" }}>
          <RankingIcon type={iconType} color={iconColor} index={index} />
        </div>
        <div
          style={{
            flexGrow: 1,
            filter: limited ? "blur(4px)" : "none",
            pointerEvents: "none",
            userSelect: "none",
          }}
        >
          <Card.Header>{course}</Card.Header>
          <Card.Meta>
            <p style={{ lineHeight: 1 }}>
              <span
                style={{ fontSize: "0.85em" }}
              >{`${course_group}／${semester}`}</span>
              <br />
              <span style={{ fontSize: "0.8em" }}>
                <Icon name="graduation cap" />
                {` ${teachers.join(" / ")}`}
              </span>
            </p>
          </Card.Meta>
        </div>
      </div>
    </LinkCard>
  );
};

const RankingIcon = (props) => {
  const { type, color, index } = props;
  const types = {
    crown: {
      name: "chess queen",
    },
    normal: {
      name: "certificate",
    },
  };
  return (
    <div
      style={{
        marginRight: "8px",
        position: "relative",
      }}
    >
      <Icon
        name={types[type].name || "certificate"}
        size="big"
        style={{
          margin: "0px",
          color: color,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: type === "crown" ? "55%" : "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <small
          style={{
            color: "white",
            fontWeight: "bold",
          }}
        >
          {index}
        </small>
      </div>
    </div>
  );
};

const LinkCard = (props) => {
  const { children, href } = props;
  return (
    <Card
      href={href}
      fluid
      style={{
        marginTop: "3px",
        textAlign: "left",
        color: "#333333",
      }}
    >
      <Card.Content
        style={{
          padding: "12px 16px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flexGrow: 1,
            }}
          >
            {children}
          </div>
          <div>
            <Icon
              name="angle right"
              style={{
                color: "gray",
              }}
            />
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

import React, { useEffect, useState } from "react";
import { Container, Icon, Image, Grid, Header, Segment } from "semantic-ui-react";

import Review from "../../organisms/Review/index";
import Stat, { getRandCriterionArray } from "../../molecules/Stat/index";

import { getUnivColor } from "../../../constants/univ";
import * as CRITERION from "../../../constants/criterion";

import { ReactComponent as CoverImage } from "../../../static/rakuda-oasis.svg";
import * as logos from "../../../static/logos/ads";
import { gaEventAd } from "../../../api/GA";
import AdApi from "../../../api/v2/AdApi";

const Ad = ({alt, src, href, maxHeight}) => {
  const handleClick = () => {
    gaEventAd(href, alt);
  };

  return (
    <div style={{textAlign: "center"}}>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        <Image
          alt={alt}
          src={src}
          style={{
            cursor: "pointer",
            display: "inline-block",
            maxHeight: `${maxHeight}px`,
          }}
        />
      </a>
    </div>
  );
};

const Landing = ({univ_id = "", ...otherProps}) => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    const adApi = new AdApi();
    adApi
      .getAds()
      .then((ads) => {
        setAds(
          ads.filter(
            (ad) =>
              !ad.is_fixed &&
              (ad.limited_to.length === 0 || ad.limited_to.includes(univ_id))
          ) // 全体向けとその大学向けの広告を取得／全体のページでは全体向けの広告のみ表示される
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [univ_id]);

  const today = new Date();

  return (
    <div {...otherProps} style={{paddingBottom: "32px"}}>
      <Container text centered>
        <Grid textAlign="center" style={{marginTop: "16px"}}>
          {ads.map((ad) => (
            <Grid.Column
              computer={8}
              mobile={16}
              style={{padding: "0px"}}
              key={ad.event_label}
            >
              <Ad
                src={ad.image_url.url}
                alt={ad.event_label}
                href={ad.href}
                maxHeight={80}
              />
            </Grid.Column>
          ))}
        </Grid>
      </Container>
      <Container>
        <Header as="h1" textAlign="center" style={{margin: "64px 0px 32px"}}>
          大学生の履修選択をラクに
          <Header.Subheader>
            「楽単らくだ」は、履修選択に悩める大学生のための情報共有プラットフォームです
          </Header.Subheader>
        </Header>
      </Container>
      <div
        style={{
          height: "240px",
          marginTop: "24px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <CoverImage
          style={{
            position: "absolute",
            height: "100%",
            top: "-9999px",
            left: "-9999px",
            right: "-9999px",
            bottom: "-9999px",
            margin: "auto",
          }}
        />
      </div>
      {otherProps.children}

      <Container text>
        <Header as="h1" textAlign="center" style={{margin: "48px 0px 24px"}}>
          授業評価をまとめ読み
          <Header.Subheader>
            楽単らくだでは、履修選択に役立つ情報を見やすくまとめました。
            <br />
            グラフで授業情報の傾向もわかります。
          </Header.Subheader>
        </Header>
        <div
          style={{
            position: "relative",
            margin: "48px 0px",
          }}
        >
          <Review
            raised
            style={{
              width: "320px",
              margin: "0 auto",
              marginTop: "10px",
            }}
            author={{
              user_id: "xxxxxxxx",
              user_name: "らくだ花子",
              screen_name: "hanako",
              univ_id: "nagoya_u",
              icon_type: "",
            }}
            review={{
              review_id: "xxxxxxxx",
              course: "基礎セミナーA",
              teacher: [{teacher_id: -1, name: "田中 太郎"}],
              year: "2020",
              semester: "前期",
              course_group: "基礎セミナー",
              comment:
                "英語で書かれた医学の論文を週一ペースで読み、さらにそれをパワポにまとめ、発表するという授業。今後のことを考えれば、とても為になる授業だが、大変なのは言うまでもない。",
              criterion: {
                attendance_freq: CRITERION.ATTENDANCE_FREQ.ITEM.ALWAYS.key,
                scoring_method: CRITERION.SCORING_METHOD.ITEM.ONLY_REPORT.key,
                credit_easiness: CRITERION.CREDIT_EASINESS.ITEM.EASY.key,
                content_quality: CRITERION.CONTENT_QUALITY.ITEM.AVERAGE.key,
              },
              lgtm: 0,
              timestamp: 1680103136,
            }}
            disabled
            isStatic
          />
          <Review
            raised
            style={{
              width: "320px",
              margin: "0 auto",
              position: "absolute",
              top: "-10px",
              left: "10%",
            }}
            author={{
              user_id: "xxxxxxxx",
              user_name: "らく太郎",
              screen_name: "rakutaro",
              univ_id: "nagoya_u",
              icon_type: "",
            }}
            review={{
              review_id: "xxxxxxxx",
              course: "線形代数I",
              teacher: [{teacher_id: -1, name: "山田 一郎"}],
              year: "2020",
              semester: "前期",
              course_group: "全学教養科目",
              comment:
                "授業のレベルはやや高い。授業についていけなければ自分で買って勉強した方がいい。最後の授業の時に過去問をくれるので過去問を解いてあれば単位は出る。テストは持ち込み可なので楽。",
              criterion: {
                attendance_freq: CRITERION.ATTENDANCE_FREQ.ITEM.ALWAYS.key,
                scoring_method: CRITERION.SCORING_METHOD.ITEM.ONLY_EXAM.key,
                credit_easiness: CRITERION.CREDIT_EASINESS.ITEM.EASY.key,
                content_quality: CRITERION.CONTENT_QUALITY.ITEM.BAD.key,
              },
              lgtm: 0,
              timestamp: 1680103136,
            }}
            disabled
            isStatic
          />
          <Review
            raised
            style={{
              width: "320px",
              margin: "0 auto",
              position: "absolute",
              top: "10px",
              right: "10%",
              zIndex: "-999",
            }}
            author={{
              user_id: "xxxxxxxx",
              user_name: "単治郎",
              screen_name: "tanjiro",
              univ_id: "nagoya_u",
              icon_type: "",
            }}
            review={{
              review_id: "xxxxxxxx",
              course: "経済学 B",
              teacher: [{teacher_id: -1, name: "鈴木 花子"}],
              year: "2020",
              semester: "前期",
              course_group: "文系基礎科目",
              comment:
                "レジュメを作って、それを中心に進める。パワポや板書といった視覚に訴えかけるものはあまりなかった。話し方も割と早口で所々日本語もおかしく、抑揚などもないためわかりやすい授業とは言えない。",
              criterion: {
                attendance_freq: CRITERION.ATTENDANCE_FREQ.ITEM.SOMETIME.key,
                scoring_method: CRITERION.SCORING_METHOD.ITEM.ONLY_REPORT.key,
                credit_easiness: CRITERION.CREDIT_EASINESS.ITEM.EASY.key,
                content_quality: CRITERION.CONTENT_QUALITY.ITEM.AVERAGE.key,
              },
              lgtm: 0,
              timestamp: 1680103136,
            }}
            disabled
            isStatic
          />
        </div>
        <Stat
          data={{
            [CRITERION.ATTENDANCE_FREQ.key]: getRandCriterionArray(
              CRITERION.ATTENDANCE_FREQ.ITEM
            ),
            [CRITERION.SCORING_METHOD.key]: getRandCriterionArray(
              CRITERION.SCORING_METHOD.ITEM
            ),
            [CRITERION.CREDIT_EASINESS.key]: getRandCriterionArray(
              CRITERION.CREDIT_EASINESS.ITEM
            ),
            [CRITERION.CONTENT_QUALITY.key]: getRandCriterionArray(
              CRITERION.CONTENT_QUALITY.ITEM
            ),
          }}
          fill_color={getUnivColor(univ_id)}
        />
      </Container>
      <Container text>
        <Header as="h1" textAlign="center" style={{margin: "48px 0px 24px"}}>
          特徴
          <Header.Subheader>
            「楽単らくだ」は、学生目線で成長してきたプラットフォームです。
          </Header.Subheader>
        </Header>
        <Segment raised style={{padding: "20px"}}>
          <Header as="h3">
            <Icon name="compass" color="grey" />
            名古屋大学生有志らによる{today.getFullYear() - 2013}
            年以上の運用実績
          </Header>
          <p>
            有志らによって、 名古屋大学での授業評価サイトを
            {today.getFullYear() - 2013}年以上にわたり運用してきました。
            履修登録の時期には多くの名大生に使われています。
            運用経験を基に、システムの改善も行ってきました。
            2020年以降、新型コロナの影響でオンライン化がさらに進み、対面で情報共有できる場が減っています。
            他大学の学生からも情報不足に困っているとの声を耳にし、全国展開を始めました。
          </p>
        </Segment>
        <Segment raised style={{padding: "20px"}}>
          <Header as="h3">
            <Icon name="handshake outline" color="grey" />
            学生に寄り添う革新的な機能
          </Header>
          <p>
            学生のために設計された快適な授業評価システムを提供しています。
            「オートコンプリート機能」により、うろ覚えの講義名や教員名でも簡単に入力できます。
            また、「AIを使った講義ランキング」機能を活用して、人気の講義が一目でわかります。
            さらに、「時間割自動作成機能」を使用することで、らくだ独自AIが毎期の時間割作成の悩みを解消し、最適な時間割を提案します。
          </p>
        </Segment>
        <Segment raised style={{padding: "20px"}}>
          <Header as="h3">
            <Icon name="heart" color="grey" />
            健全な情報共有プラットフォーム形成
          </Header>
          <p>
            楽単らくだでは、名誉毀損や侮辱罪にあたる表現、 過度に暴力的な表現、
            差別的な表現の投稿を禁止しています。
            健全な情報共有プラットフォーム形成のために 運営側も努めていきます。
          </p>
        </Segment>
      </Container>
      <Container text>
        <Header as="h1" textAlign="center" style={{margin: "48px 0px 24px"}}>
          らくだスポンサー
          <Header.Subheader>
            楽単らくだはスポンサー企業様のご支援により成り立っています。お問い合わせは
            <a href="https://www.hashup.co.jp/contact" target="_blank">
              こちら
            </a>
            から
          </Header.Subheader>
        </Header>
        <Grid centered>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoChudenCTI}
              alt="中電シーティーアイ Logo"
              href="https://www.cti.co.jp/"
              maxHeight={100}
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoTohoTec}
              alt="東朋テクノロジー Logo"
              href="https://www.toho-tec.co.jp/"
              maxHeight={100}
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoDigitalMix}
              alt="DigitalMix Logo"
              href="https://www.digitalmix.jp"
              maxHeight={100}
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoSaisonCard}
              alt="SaisonCard Logo"
              href="https://corporate.saisoncard.co.jp/"
              maxHeight={100}
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoGrandCentral}
              alt="GrandCentral Logo"
              href="https://grandcentral.jp"
              maxHeight={100}
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoOnePile}
              alt="OnePile Logo"
              href="https://onepile.jp"
              maxHeight={100}
            />
          </Grid.Column>
        </Grid>
      </Container>
      <Container text>
        <Header as="h1" textAlign="center" style={{margin: "48px 0px 24px"}}>
          つながるらくだ
          <Header.Subheader>
            楽単らくだは学生団体を応援しています。
          </Header.Subheader>
        </Header>
        <Grid centered>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoNUTFC}
              alt="名古屋大学陸上競技部 Logo"
              href="https://www.nutfc.com/"
              maxHeight={100}
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoGrampus}
              alt="名古屋大学アメリカンフットボール部GRAMPUS Logo"
              href="http://nu-grampus.com/"
              maxHeight={100}
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoIki}
              alt="フリーペーパー『粋』 Logo"
              href="http://iki.nagoya"
              maxHeight={100}
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoNURC}
              alt="名古屋大学漕艇部 Logo"
              href="https://nagoyaunivrowing.wixsite.com/meidai-rowing"
              maxHeight={100}
            />
          </Grid.Column>
        </Grid>
      </Container>
      <Container text>
        <Header as="h1" textAlign="center" style={{margin: "48px 0px 24px"}}>
          メディア掲載・受賞歴
          <Header.Subheader>
            楽単らくだは高い評価を頂いています。
          </Header.Subheader>
        </Header>
        <Grid centered>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoChunichi}
              alt="中日新聞 Logo"
              href="https://edu.chunichi.co.jp/news/detail/10527"
              maxHeight={100}
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoNikkan}
              alt="日刊工業新聞 Logo"
              href="https://www.nikkan.co.jp/articles/view/577343"
              maxHeight={100}
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoTongali}
              alt="Tongali Logo"
              href="https://www.hashup.co.jp/biz-contest2020"
              maxHeight={100}
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={7}>
            <Ad
              src={logos.LogoEarthNote}
              alt="EarthNote Logo"
              href="https://www.yoridori.jp/earth-note/interview-hashup"
              maxHeight={100}
            />
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
};
export default Landing;

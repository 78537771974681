import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  TextArea,
  Modal,
} from "semantic-ui-react";

import { shareReviewOnTwitter } from "../../../api/Sharing";
import Navbar from "../Navbar/index";
import Footer from "../Footer/index";
import * as ROUTES from "../../../constants/routes";
import UNIV from "../../../constants/univ";
import * as CRITERION from "../../../constants/criterion";
import ReviewApi from "../../../api/v2/ReviewApi";
import TeacherApi from "../../../api/v2/TeacherApi";
import CourseApi from "../../../api/v2/CourseApi";

const PostReview = () => {
  const navigate = useNavigate();
  const timer = useRef(null);
  const { univ_id = "" } = useParams();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState({ course: false, teacher: false });
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [form, setForm] = useState({
    course: {
      key: "course_name",
      title: "講義名",
      value: "",
      required: true,
    },
    teacher: {
      key: "teachers",
      title: "教員名",
      value: [],
      required: true,
    },
    course_group: {
      key: "course_group",
      text: "科目区分",
      value: "その他",
      required: true,
    },
    year: {
      key: "year",
      text: "年度",
      value: new Date().getFullYear(),
      required: true,
      items: [...Array(new Date().getFullYear() + 1).keys()]
        .slice(new Date().getFullYear() - 5)
        .map((item) => ({ key: item, value: item, text: item }))
        .reverse(),
    },
    semester: {
      key: "semester",
      text: "学期",
      value: "",
      required: true,
      items: ["前期", "後期"].map((item) => ({
        key: item,
        value: item,
        text: item,
      })),
    },
    ATTENDANCE_FREQ: {
      key: CRITERION.ATTENDANCE_FREQ.key,
      value: CRITERION.ATTENDANCE_FREQ.ITEM.SOMETIME.key,
      required: true,
    },
    SCORING_METHOD: {
      key: CRITERION.SCORING_METHOD.key,
      value: CRITERION.SCORING_METHOD.ITEM.ONLY_EXAM.key,
      required: true,
    },
    CREDIT_EASINESS: {
      key: CRITERION.CREDIT_EASINESS.key,
      value: CRITERION.CREDIT_EASINESS.ITEM.NORMAL.key,
      required: true,
    },
    CONTENT_QUALITY: {
      key: CRITERION.CONTENT_QUALITY.key,
      value: CRITERION.CONTENT_QUALITY.ITEM.AVERAGE.key,
      required: true,
    },
    comment: {
      key: "comment",
      text: "comment",
      value: "",
      required: true,
    },
    anonymous: {
      key: "anonymous",
      value: false,
      required: false,
    },
  });
  const [options, setOptions] = useState({
    selected: { course: [], teacher: [] },
    results: { course: [], teacher: [] },
  });
  const [teachersOfCourse, setTeachersOfCourse] = useState([]);

  const createOption = (value) => ({ key: value, value: value, text: value });

  // フォーム項目の更新処理
  const setFormItem = (obj) => {
    setForm((prevInputSelect) => {
      const updatedInputSelect = { ...prevInputSelect };
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          updatedInputSelect[key].value = obj[key];
          updatedInputSelect[key].is_invalid = false;
        }
      }
      return updatedInputSelect;
    });
  };

  // 有効性フラグ更新処理
  const setIsInvalid = (obj) => {
    setForm((prevInputSelect) => {
      const updatedInputSelect = { ...prevInputSelect };
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          updatedInputSelect[key].is_invalid = obj[key]; // bool
        }
      }
      return updatedInputSelect;
    });
  };

  // レビュー投稿処理
  const handlePostReview = () => {
    setIsLoading(true);

    // 投稿データ作成とバリデーション
    const data = {
      timestamp: new Date().getTime() / 1000,
      univ_id: univ_id,
    };
    let valid = true;
    for (const item in form) {
      data[form[item].key] = form[item].value; // ここで投稿データを作成
      if (
        JSON.stringify(form[item].value) !== JSON.stringify([]) && // teacherは配列が渡ってくるが、空配列の場合は不正な値として扱う
        form[item].value
      ) {
        setIsInvalid({ [item]: false });
      } else {
        if (form[item].required) {
          // 設定が必須の場合
          setIsInvalid({ [item]: true });
          valid = false;
        }
      }
    }

    if (!valid) {
      alert("未入力の項目があります");
      setIsLoading(false);
      return false;
    }

    if (!window.confirm("レビューを投稿します")) {
      // キャンセル
      setIsLoading(false);
      return false;
    } else {
      // レビュー投稿
      const reviewApi = new ReviewApi();
      const postData = {
        univId: data.univ_id,
        courseName: data.course_name,
        courseGroup: data.course_group,
        semester: data.semester,
        teachers: data.teachers,
        year: data.year,
        attendanceFreq: data.attendance_freq,
        scoringMethod: data.scoring_method,
        creditEasiness: data.credit_easiness,
        contentQuality: data.content_quality,
        comment: data.comment,
        anonymous: data.anonymous,
      };
      reviewApi
        .postReview(postData)
        .then(() => {
          alert("レビューを投稿しました！");
          setIsLoading(false);
          setIsModalOpened(true);
        })
        .catch((err) => {
          alert("投稿に失敗しました…。時間をおいて再度お試しください。");
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  // 講義名が変化した際の処理
  const handleCourseChange = (univId, name, searchQuery) => {
    clearTimeout(timer.current);
    setLoading((prevLoading) => ({ ...prevLoading, [name]: true }));

    timer.current = setTimeout(() => {
      const courseApi = new CourseApi();
      const teacherApi = new TeacherApi();
      // 講義名の候補を取得
      courseApi
        .getUnivCourses(univId, searchQuery)
        .then((res) => {
          const currentValue = form.course.value;
          const courses = Array.from(new Set(res.map((course) => course.name))); // 重複を削除
          const courseOptions = courses
            .map((course) => createOption(course))
            .filter((option) => option.value !== currentValue); // 現在の値と同じものはoptionが重複するので除外
          setOptions((prevOptions) => ({
            ...prevOptions,
            results: {
              ...prevOptions.results,
              [name]: courseOptions,
            },
          }));
        })
        .finally(() => {
          setLoading((prevLoading) => ({
            ...prevLoading,
            [name]: false,
          }));
        });

      // 講義名に紐づく教員名を取得
      teacherApi
        .getUnivCourseTeachers(univId, searchQuery)
        .then((res) => {
          let teachers = res.map((teacher) => teacher.name);
          const teacherFullNameSort = (teachers) => {
            // teachers の名字のみのものを後に表示するために、配列を分けて後ろに追加
            // 名字のみのものは、" " または "." で区切られていないものとする
            let full_name_teachers = [];
            let last_name_teachers = [];
            for (let teacher of teachers) {
              if (teacher.match(/^[^ .]+$/)) {
                last_name_teachers.push(teacher);
              } else {
                full_name_teachers.push(teacher);
              }
            }
            teachers = full_name_teachers.concat(last_name_teachers);
            return teachers;
          };
          teachers = teacherFullNameSort(teachers);
          setTeachersOfCourse(teachers);
        })
        .finally(() => {
          setLoading((prevLoading) => ({
            ...prevLoading,
            course: false,
            teacher: false,
          }));
        });
    }, 1000); // 1秒間入力がなければAPIを叩く
  };

  // 教員名のオートコンプリート
  const handleTeacherChange = (univId, name, searchQuery) => {
    clearTimeout(timer.current);
    setLoading((prevLoading) => ({ ...prevLoading, [name]: true }));

    timer.current = setTimeout(() => {
      const teacherApi = new TeacherApi();
      const currentValue = [...form.teacher.value, searchQuery];
      // 教員名の候補を取得
      teacherApi
        .getUnivTeachers(univId, searchQuery)
        .then((res) => {
          const teachers = res.map((teacher) => teacher.name);
          const teacherOptions = teachers
            .map((teacher) => createOption(teacher))
            .filter((option) => !currentValue.includes(option.value)); // 現在の値と同じものはoptionが重複するので除外
          setOptions((prevOptions) => ({
            ...prevOptions,
            results: {
              ...prevOptions.results,
              [name]: teacherOptions,
            },
          }));
        })
        .finally(() => {
          setLoading((prevLoading) => ({
            ...prevLoading,
            [name]: false,
          }));
        });
    }, 1000); // 1秒間入力がなければAPIを叩く
  };

  const handleSelectChange = (e, { name, value }) => {
    // 値を整形
    switch (name) {
      case "course":
        value = value
          .trim()
          .replace(/\s+/g, " ")
          .replace("（", "(")
          .replace("）", ")")
          .replace("Ⅰ", "I")
          .replace("Ⅱ", "II")
          .replace("Ⅲ", "III");
        break;
      case "teacher":
        // valueは配列で渡される
        // 教員検索により追加したoptionはvalueが配列になっているため、渡ってくるvalueが[str, array]の形になっている
        // 本来は[str]のため、Warningが出る可能性がある
        if (value.length > 0 && typeof value[value.length - 1] === "object") {
          // valueが文字列ではなく配列になっているoptionを選択したとき
          value = value[value.length - 1];
        }
        value = value.map((item) => item.trim().replace(/\s+/g, " "));
        break;
      default:
        break;
    }

    // フォーム項目の更新
    setFormItem({ [name]: value });

    // 教員名の候補をリセット
    switch (name) {
      case "course":
        setOptions((prevOptions) => ({
          ...prevOptions,
          selected: {
            ...prevOptions.selected,
            [name]: value !== "" ? [createOption(value)] : [],
          },
          results: {
            ...prevOptions.results,
            [name]: [],
          },
        }));
        setTeachersOfCourse(value === "" ? [] : teachersOfCourse);
        break;
      case "teacher":
        setOptions((prevOptions) => ({
          ...prevOptions,
          selected: {
            ...prevOptions.selected,
            [name]: value.map((elm) => createOption(elm)),
          },
          results: {
            ...prevOptions.results,
            [name]: [],
          },
        }));
        break;
      default:
        break;
    }
  };

  // 入力値が変化したときの処理
  const handleSearchChange = (e, { name, searchQuery }) => {
    switch (name) {
      case "course":
        // 講義名が空白の場合は、teachers_of_courseを空にする
        if (searchQuery.length === 0) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            results: {
              ...prevOptions.results,
              [name]: [],
            },
          }));
          setLoading((prevLoading) => ({
            ...prevLoading,
            [name]: false,
          }));
          setTeachersOfCourse([]);
          break;
        }
        // 講義名のオートコンプリート／教員名の候補取得
        handleCourseChange(univ_id, name, searchQuery);
        break;
      case "teacher":
        if (searchQuery.length === 0) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            results: {
              ...prevOptions.results,
              [name]: [],
            },
          }));
          setLoading((prevLoading) => ({
            ...prevLoading,
            [name]: false,
          }));
          setTeachersOfCourse(form.course.value === "" ? [] : teachersOfCourse);
          break;
        }
        // 教員名のオートコンプリート
        handleTeacherChange(univ_id, name, searchQuery);
        break;
      default:
        break;
    }
  };

  // 教員名のドロップダウンのオプションを整形
  const teacherDropdownOptions = () => {
    let optionsList = [];
    if (options.selected.teacher) {
      optionsList = optionsList.concat(options.selected.teacher);
    }
    if (options.results.teacher) {
      optionsList = optionsList.concat(options.results.teacher);
    }
    if (teachersOfCourse) {
      optionsList = optionsList.concat(
        teachersOfCourse.map((teacher) => ({
          key: teacher,
          value: teacher,
          text: teacher,
          content: (
            <div>
              <span style={{ color: "gray" }}>もしかして...　</span>
              {teacher}
            </div>
          ),
        }))
      );
    }
    // keyが重複しているものを除外
    return optionsList.filter(
      (option, index, self) =>
        self.findIndex((t) => t.key === option.key) === index
    );
  };

  const createCourseGroupItem = (univ_id) => {
    return (
      UNIV[univ_id]?.course_group
        ? UNIV[univ_id]?.course_group
        : ["学部授業", "大学院授業"]
    )
      .concat(["その他", "不明"])
      .map((item) => {
        return { key: item, value: item, text: item };
      });
  };

  const createSemesterItem = (univ_id) => {
    return (UNIV[univ_id]?.periods ? UNIV[univ_id].periods : ["前期", "後期"])
      .concat(["その他", "不明"])
      .map((item) => {
        return { key: item, value: item, text: item };
      });
  };

  useEffect(() => {
    // 大学が存在しない場合は404ページへ
    if (UNIV[univ_id].status !== "open") {
      navigate(ROUTES.NOT_FOUND);
    }
    // ユーザー情報取得
    Auth.currentAuthenticatedUser()
      .then((user) => setUser(user))
      .catch(() => null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navbar
        style={{ marginBottom: "24px" }}
        univ_id={univ_id}
        subtitle={"for " + UNIV[univ_id].name}
        show_pusher={false}
        show_control={false}
      />
      <Container text>
        <Header as="h1">
          <Header.Content>
            授業を評価する
            <Header.Subheader>
              出来る限り正確な情報を書いてください。
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Form>
          <Form.Dropdown
            label={
              <label>
                講義名
                {form.course.is_invalid && (
                  <Icon name="exclamation circle" color="red" />
                )}
              </label>
            }
            name="course"
            options={[...options.selected.course, ...options.results.course]}
            value={form.course.value}
            placeholder="例: 物理学基礎"
            loading={loading.course}
            fluid
            search
            selection
            clearable
            scrolling
            closeOnChange
            allowAdditions
            additionLabel="講義名を追加: "
            noResultsMessage="講義名を入力してください"
            icon="search"
            onSearchChange={handleSearchChange}
            onChange={handleSelectChange}
          />
          <Form.Field>
            <label>
              教員名
              <br />
              {form.teacher.is_invalid && (
                <Icon name="exclamation circle" color="red" />
              )}
            </label>
            <Dropdown
              name="teacher"
              options={teacherDropdownOptions()}
              value={form.teacher.value}
              placeholder="例: 田中 太郎"
              loading={loading.teacher}
              fluid
              multiple
              search
              selection
              clearable
              scrolling
              closeOnChange
              allowAdditions
              additionLabel="教員名を追加: "
              noResultsMessage="教員名を入力してください"
              icon="search"
              onSearchChange={handleSearchChange}
              onChange={handleSelectChange}
            />
            <p style={{ color: "gray", marginTop: "3px" }}>
              <small>
                複数名可。姓と名の間には半角スペースを入れてください。
              </small>
            </p>
          </Form.Field>
          {["course_group"].map((item, index) => (
            <Form.Field key={index}>
              <label>
                {form[item].text}
                {form[item].is_invalid && (
                  <Icon name="exclamation circle" color="red" />
                )}
              </label>
              <Dropdown
                selection
                options={[...createCourseGroupItem(univ_id)]}
                value={form[item].value}
                onChange={(e, data) => setFormItem({ [item]: data.value })}
              />
            </Form.Field>
          ))}
          <Grid columns={2}>
            {["year"].map((item, index) => (
              <Grid.Column key={index}>
                <Form.Field>
                  <label>
                    {form[item].text}
                    {form[item].is_invalid && (
                      <Icon name="exclamation circle" color="red" />
                    )}
                  </label>
                  <Dropdown
                    selection
                    compact
                    options={form[item].items}
                    value={form[item].value}
                    onChange={(e, data) => setFormItem({ [item]: data.value })}
                  />
                </Form.Field>
              </Grid.Column>
            ))}
            {["semester"].map((item, index) => (
              <Grid.Column key={index}>
                <Form.Field key={index}>
                  <label>
                    {form[item].text}
                    {form[item].is_invalid && (
                      <Icon name="exclamation circle" color="red" />
                    )}
                  </label>
                  <Dropdown
                    selection
                    compact
                    options={createSemesterItem(univ_id)}
                    value={form[item].value}
                    onChange={(e, data) => setFormItem({ [item]: data.value })}
                  />
                </Form.Field>
              </Grid.Column>
            ))}
          </Grid>
          <div style={{ margin: "24px 0" }}>
            {[
              "ATTENDANCE_FREQ",
              "SCORING_METHOD",
              "CREDIT_EASINESS",
              "CONTENT_QUALITY",
            ].map((c_key, c_index) => (
              <Form.Field key={c_index}>
                <label>{CRITERION[c_key].text}</label>
                {Object.keys(CRITERION[c_key].ITEM).map((i_key, i_index) => {
                  const isSelected =
                    form[c_key].value === CRITERION[c_key].ITEM[i_key].key;
                  return (
                    <Label
                      key={i_index}
                      color="grey"
                      as="a"
                      style={{ marginBottom: "4px" }}
                      basic={!isSelected}
                      onClick={() =>
                        setFormItem({
                          [c_key]: CRITERION[c_key].ITEM[i_key].key,
                        })
                      }
                    >
                      {isSelected && <Icon name="check" />}
                      {CRITERION[c_key].ITEM[i_key].text}
                    </Label>
                  );
                })}
              </Form.Field>
            ))}
          </div>
          <Form.Field>
            <label>
              コメント
              {form.comment.is_invalid && (
                <Icon name="exclamation circle" color="red" />
              )}
            </label>
            <p style={{ color: "gray", marginBottom: "10px" }}>
              <small>
                名誉毀損にあたる表現や攻撃的、差別的な表現は禁止されています。
              </small>
            </p>
            <TextArea
              rows={5}
              placeholder="コメントを入力"
              onChange={(e, data) => setFormItem({ comment: data.value })}
            />
          </Form.Field>
          {user && (
            <>
              <Form.Field>
                <label>投稿設定</label>
                <p style={{ color: "gray" }}>
                  <small>匿名で投稿する場合はチェックを入れてください。</small>
                </p>
                <Form.Checkbox
                  label="匿名で投稿する ※ランキングには反映されません"
                  onChange={(e, data) =>
                    setFormItem({ anonymous: data.checked })
                  }
                />
              </Form.Field>
              <p style={{ color: "gray", lineHeight: 1.1 }}>
                <small>
                  ※名誉毀損や侮辱罪にあたる表現、過度に暴力的な表現、差別的な表現は
                  <a target="_blank" to={ROUTES.TERMS}>
                    利用規約
                  </a>
                  第2条により禁止されています。
                </small>
              </p>
            </>
          )}
        </Form>
        <div style={{ marginTop: "24px" }}>
          <p>
            <small>
              投稿により、
              <a target="_blank" to={ROUTES.TERMS}>
                利用規約
              </a>
              に同意にしたものとします。 また、
              <a target="_blank" to={ROUTES.PRIVACY}>
                プライバシーポリシー
              </a>
              に基づき、投稿者の IP アドレスは記録されます。
            </small>
          </p>

          <div style={{ textAlign: "center" }}>
            <Button
              loading={isLoading}
              disabled={isLoading}
              primary
              onClick={handlePostReview}
            >
              投稿する
            </Button>
          </div>
        </div>

        <Modal size="mini" open={isModalOpened}>
          <Modal.Header>Twitter共有</Modal.Header>
          <Modal.Content>
            <p>投稿内容をTwitterで共有しますか？</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                setIsModalOpened(false);
                navigate(ROUTES.APP.HOME.replace(":univ_id", univ_id));
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => {
                setIsModalOpened(false);
                navigate(ROUTES.APP.HOME.replace(":univ_id", univ_id));
                const course = form.course.value;
                const comment = form.comment.value;
                shareReviewOnTwitter(course, comment);
              }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
      <Footer univ_id={univ_id} />
    </>
  );
};

export default PostReview;

import React from "react";

import styles from "./style.module.scss";

import { gaEventAd } from "../../../api/GA";

const Ad = ({url, label, src}) => {
  const handleClick = () => {
    if (!url) return;
    gaEventAd(url, label);
  };

  if (!url) return null;

  return (
    <a href={url} onClick={handleClick}>
      <div className={styles.ad}>
        <img src={src} alt="" />
      </div>
    </a>
  );
};

export default Ad;

import React from "react";

import Review from "../Review/index";
import { Feed } from "./Feed";
export const FeedList = (props) => {
  const {
    feeds = [],
    user_id = "abcdef",
    loading = false,
    disabled = false,
    clickable = false,
    limited = false,
    is_admin = false,
  } = props;

  if (!loading) {
    return (
      <div>
        {feeds.map(({ type, data }, i) => {
          switch (type) {
            case "review": // reviewには動的な情報を追加で渡す必要がある
              return (
                <Feed
                  key={data.review_id}
                  type={type}
                  data={{
                    ...data,
                    is_posted_by_user:
                      user_id === data.author.user_id || is_admin,
                    disabled: disabled,
                    clickable: clickable,
                    limited: limited,
                  }}
                />
              );
            default:
              return <Feed key={i} type={type} data={data} />;
          }
        })}
      </div>
    );
  } else {
    return <Review loading />;
  }
};

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";

import {
  Button,
  Header,
  Container,
  Grid,
  Menu,
  Icon,
  Segment,
} from "semantic-ui-react";

import Navbar from "../../organisms/Navbar/index";
import Footer from "../../organisms/Footer/index";

import * as ROUTES from "../../../constants/routes";
import UNIV from "../../../constants/univ";
import UserApi from "../../../api/v2/UserApi";
import ReviewApi from "../../../api/v2/ReviewApi";
import { convertPublicUserSchemaToUser } from "../../../api/v2/ApiUtils";
import RakudaIcon from "../../atoms/RakudaIcon";
import { ICON_TYPES } from "../../../constants/icon_types";
import { LoadingContent } from "../../atoms/LoadingContent";
import Review from "../../organisms/Review/index";
import { convertReviewSchemaToReview } from "../../../api/v2/ApiUtils";

const sampleComment = {
  author: {
    user_id: "xxxxxxxx-hanako",
    user_name: "らくたんはなこ",
    screen_name: "rakutan_hanako",
    univ_id: "nagoya_u",
    icon_type: "rakuda_theme",
  },
  comment:
    "これ確かに楽単でした。これ確かに楽単でした。これ確かに楽単でした。これ確かに楽単でした。",
  review_id: "xxxxxxxx",
  lgtm: 14,
  timestamp: 1680103136,
};

const UserPageTemplate = () => {
  const [activeTab, setActiveTab] = useState("review");
  const [currentUser, setCurrentUser] = useState(null);
  const [user, setUser] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [comments, setComments] = useState([sampleComment, sampleComment, sampleComment]); // nullでもいい
  const [reviewCount, setReviewCount] = useState(0);
  const [commentCount, setCommentCount] = useState(999);
  const [loadingReviews, setLoadingReviews] = useState(false);

  const navigate = useNavigate();
  const { user_id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userApi = new UserApi();
        const fetchedUser = await userApi.getUser(user_id);
        setUser(convertPublicUserSchemaToUser(fetchedUser));
      } catch (err) {
        // もし404なら404ページにリダイレクト
        if (err.response && err.response.status === 404) {
          navigate(ROUTES.NOT_FOUND);
        } else {
          console.error(err);
        }
      }

      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setCurrentUser(currentUser);
      } catch (err) {
        console.error("User not authenticated");
      }

      setLoadingReviews(true);
      try {
        // レビューを取得
        const reviewApi = new ReviewApi();
        const fetchedReviews = await reviewApi.getUserReviews(user_id);
        setReviews(fetchedReviews.map((review) => convertReviewSchemaToReview(review)));
        setReviewCount(fetchedReviews.length);
      } catch (err) {
        console.error(err);
      }

      // TODO: レビュー数、コメント数、コメントを取得する
      setLoadingReviews(false);
    };

    fetchData();
  }, [user_id, navigate]);

  return (
    <>
      <Helmet>
        <title>楽単らくだ</title>
        <meta
          name="description"
          content={
            "「楽単らくだ」は、履修選択に悩める大学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
          }
        />
        <meta name="robots" content="index" />
      </Helmet>
      <Navbar
        style={{ marginBottom: "24px" }}
        subtitle={"授業評価"}
        show_pusher={false}
        show_control={false}
      />
      <Container>
        {user ? (
          <Grid stackable>
            <Grid.Column width={4} textAlign="center">
              <div style={{ maxWidth: "250px", margin: "auto" }}>
                <RakudaIcon
                  bg_color={ICON_TYPES[user.icon_type]?.color}
                  circular
                  style={{
                    display: "block",
                    maxWidth: "160px",
                    margin: "0 auto",
                  }}
                />
                <Header as="h2">
                  <Header.Content>{user.user_name}</Header.Content>
                  <Header.Subheader>@{user.screen_name}</Header.Subheader>
                </Header>
                <div style={{ color: "grey" }}>
                  <div>
                    <Icon name="student" />
                    {UNIV[user.univ_id].name}
                  </div>
                  {/* TODO: コメント数表示 */}
                  <div>
                    {reviewCount} 投稿
                    {/* ・{commentCount} コメント */}
                  </div>
                </div>
                <p style={{ textAlign: "left", margin: "10px 0px" }}>
                  {user.introduction}
                </p>
                <div style={{ textAlign: "left", color: "royalblue" }}>
                  {user.community !== "" && <span>#{user.community}</span>}
                </div>
                {currentUser?.attributes.sub === user.user_id && (
                  <Link to={ROUTES.APP.USER.EDIT.replace(":user_id", user.user_id)}>
                    <Button primary fluid style={{ margin: "30px 0px" }}>
                      プロフィールを編集する
                    </Button>
                  </Link>
                )}
              </div>
            </Grid.Column>
            <Grid.Column width={12}>
              <Menu pointing secondary>
                <Menu.Item
                  name="review"
                  active={activeTab === "review"}
                  onClick={() => setActiveTab("review")}
                >
                  レビュー
                </Menu.Item>
                <Menu.Item
                  name="comment"
                  active={activeTab === "comment"}
                  onClick={() => setActiveTab("comment")}
                >
                  コメント
                </Menu.Item>
              </Menu>
              {activeTab === "review" && (
                <>
                  {reviews.length > 0 || loadingReviews ? (
                    reviews.map((review) => (
                      <Review
                        key={review.review_id}
                        author={review.author}
                        review={review.review}
                        comments={review.comments}
                        is_posted_by_user={
                          currentUser?.attributes.sub === review.author.user_id ||
                          currentUser?.signInUserSession.idToken.payload["cognito:groups"]?.includes("admin")
                        }
                        limited={!currentUser}
                        clickable
                      />
                    ))
                  ) : (
                    <div>
                      {currentUser?.attributes.sub === user.user_id && (
                        <Segment style={{ textAlign: "center" }}>
                          <p>
                            まだ投稿がありません
                            <br />
                            あなたもレビューを投稿してみませんか？🌵
                          </p>
                          <Link to={ROUTES.APP.POST.replace(":univ_id", user.univ_id)}>
                            <Icon name="paper plane" />
                            投稿する
                          </Link>
                        </Segment>
                      )}
                    </div>
                  )}
                </>
              )}
              {activeTab === "comment" && (
                <>
                  <Segment style={{ textAlign: "center" }}>
                    Coming soon...🐫
                  </Segment>
                  {/* TODO: ユーザコメント一覧 */}
                  {/* {comments.length > 0 ? (
                      comments.map((comment) => (
                        <CommentCard
                          author={comment.author}
                          comment={comment.comment}
                          review_id={comment.review_id}
                          lgtm={comment.lgtm}
                          timestamp={comment.timestamp}
                        />
                      ))
                    ) : (
                      <div>
                        <p>まだコメントがありません</p>
                      </div>
                  )} */}
                </>
              )}
            </Grid.Column>
          </Grid>
        ) : (
          <LoadingContent />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default UserPageTemplate;

import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { Segment, Button } from "semantic-ui-react";

import * as ROUTES from "../../../../../../../constants/routes";
import { gaEventRegister } from "../../../../../../../api/GA";

export const LimitedPanel = () => {
  const navigate = useNavigate();
  return (
    <Segment.Group
      raised
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        width: "80%",
        margin: "0px",
        padding: "16px 24px",
        backgroundColor: "white",
      }}
    >
      <Segment>
        <p style={{ color: "gray", fontFamily: "'M PLUS Rounded 1c'" }}>
          <span style={{ display: "inline-block" }}>無料のユーザー登録で</span>
          <span style={{ display: "inline-block" }}>
            時間割をらくらく自動作成！
          </span>
        </p>
      </Segment>
      <Segment>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            gaEventRegister("TimeTable"); // GAイベント送信
            navigate(ROUTES.APP.AUTH);
          }}
          color="instagram"
          style={{
            marginRight: "10px",
            display: "block",
            margin: "12px auto 8px",
            fontFamily: "sans-serif",
          }}
        >
          無料でユーザー登録
        </Button>
        <Link
          style={{ textShadow: "0 0 8px white", fontFamily: "sans-serif" }}
          to={ROUTES.APP.AUTH}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          ログインはこちら
        </Link>
      </Segment>
    </Segment.Group>
  );
};

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Auth } from "aws-amplify";
import {
  Button,
  Header,
  Container,
  Grid,
  Form,
  Checkbox,
  Icon,
  Dropdown,
} from "semantic-ui-react";

import Navbar from "../../organisms/Navbar/index";
import Footer from "../../organisms/Footer/index";
import * as ROUTES from "../../../constants/routes";
import UNIV from "../../../constants/univ";
import {
  convertUserPrivateSchemaToPrivateUser,
  convertPrivateUserToUserUpdateSchema,
} from "../../../api/v2/ApiUtils";
import UserApi from "../../../api/v2/UserApi";
import RakudaIcon from "../../atoms/RakudaIcon";
import { LoadingContent } from "../../atoms/LoadingContent";
import {
  ICON_TYPES,
  getLimitedIconTypeOptions,
} from "../../../constants/icon_types";
import CommunityApi from "../../../api/v2/CommunityApi";
import { NONE_MAJOR_OPTION } from "../../../constants/user";

const USER_NAME_LIMIT = 32;
const INTRODUCTION_LIMIT = 150;
const COMMUNITY_LIMIT = 100;

const date = new Date();
date.setMonth(date.getMonth() - 3); // 年度を取得
const currentYear = date.getFullYear();

const gradeOptions = [
  { key: currentYear, value: currentYear, text: "大学1年生" },
  { key: currentYear - 1, value: currentYear - 1, text: "大学2年生" },
  { key: currentYear - 2, value: currentYear - 2, text: "大学3年生" },
  { key: currentYear - 3, value: currentYear - 3, text: "大学4年生" },
  { key: currentYear - 4, value: currentYear - 4, text: "大学院1年生" },
  { key: currentYear - 5, value: currentYear - 5, text: "大学院2年生" },
];
const iconOptions = getLimitedIconTypeOptions(0);
const careerPathOptions = [
  { key: false, value: false, text: "学部で就職予定" },
  { key: true, value: true, text: "大学院に進学予定／在学中" },
];
const createOption = (value) => ({
  key: value,
  value: value,
  text: value,
});

const UserEditPageTemplate = () => {
  const navigate = useNavigate();
  const { user_id } = useParams();
  const timer = useRef(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [communityOptions, setCommunityOptions] = useState([]);
  const [communityFetchLoading, setCommunityFetchLoading] = useState(false);
  const [form, setForm] = useState({
    user_name: { value: "", errorMessage: "" },
    screen_name: { value: "", errorMessage: "" },
    univ_id: { value: "", errorMessage: "" },
    department: { value: "", errorMessage: "" },
    major: { value: "", errorMessage: "" },
    grade: { value: "", errorMessage: "" },
    icon_type: { value: "", errorMessage: "" },
    introduction: { value: "", errorMessage: "" },
    community: { value: "", errorMessage: "" },
    score: { value: 0, errorMessage: "" },
    planning_grad_school: { value: false, errorMessage: "" },
    is_employed: { value: false, errorMessage: "" },
    admit_mail: { value: true, errorMessage: "" },
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // 自分のプロフィール編集画面でなければユーザページへ遷移
        const currentUserId = user.attributes.sub;
        if (currentUserId !== user_id) {
          navigate(ROUTES.APP.USER.HOME.replace(":user_id", user_id));
        }
        // ユーザ情報を取得
        const userApi = new UserApi();
        userApi.getPrivateUser(user_id).then((user) => {
          const privateUser = convertUserPrivateSchemaToPrivateUser(user);
          setUser(privateUser);
          const updatedForm = { ...form };
          Object.keys(updatedForm).forEach((key) => {
            updatedForm[key].value = privateUser[key];
          });
          setForm(updatedForm); // formを更新
          setDepartmentOptions(getDepartmentOptions(privateUser.univ_id)); // 所属大学に応じて学部の選択肢を変更
        });
      })
      .catch(() => {
        // ログインしていない場合はユーザページへ遷移
        navigate(ROUTES.APP.USER.HOME.replace(":user_id", user_id));
      });
    return () => {
      clearTimeout(timer.current);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDepartmentOptions = (univId) => {
    return Object.keys(UNIV[univId].department)
      .concat(["その他"])
      .map((department) => ({
        key: department,
        value: department,
        text: department,
      }));
  };

  const getMajorOptions = (univId, department) => {
    return UNIV[univId] && UNIV[univId].department[department]
      ? UNIV[univId].department[department].majors
          .map((major) => createOption(major))
          .concat([NONE_MAJOR_OPTION])
      : [NONE_MAJOR_OPTION];
  };

  const handleFormChange = (e, { name, value }) => {
    const updatedForm = { ...form };
    updatedForm[name].value = value;
    setForm(updatedForm);
    setHasChanged(true);
  };

  const handleCheckboxChange = (e, { name, checked }) => {
    const updatedForm = { ...form };
    updatedForm[name].value = checked;
    setForm(updatedForm);
    setHasChanged(true);
  };

  const validationCheck = () => {
    let isValid = true;
    const updatedForm = { ...form };
    Object.keys(updatedForm).forEach((key) => {
      // エラーメッセージを初期化
      updatedForm[key].errorMessage = "";
      // 入力内容チェック
      switch (key) {
        case "user_name":
          if (updatedForm[key].value.trim().length === 0) {
            updatedForm[key].errorMessage =
              "※1文字以上のユーザ名にしてください";
            isValid = false;
          }
          if (updatedForm[key].value.trim().length > USER_NAME_LIMIT) {
            updatedForm[
              key
            ].errorMessage = `※${USER_NAME_LIMIT}文字以内で入力してください`;
            isValid = false;
          }
          break;
        case "introduction":
          if (updatedForm[key].value.trim().length > INTRODUCTION_LIMIT) {
            updatedForm[
              key
            ].errorMessage = `※${INTRODUCTION_LIMIT}文字以内で入力してください`;
            isValid = false;
          }
          break;
        case "community":
          if (updatedForm[key].value.trim().length > COMMUNITY_LIMIT) {
            updatedForm[
              key
            ].errorMessage = `※${COMMUNITY_LIMIT}文字以内で入力してください`;
            isValid = false;
          }
          break;
        case "department":
        case "major":
        case "grade":
        case "icon_type":
          if (!updatedForm[key].value || updatedForm[key].value === "") {
            updatedForm[key].errorMessage = "※未入力です";
            isValid = false;
          }
          break;
        default:
          break;
      }
    });
    setForm(updatedForm);
    return isValid;
  };

  const handleSubmit = () => {
    setLoading(true);
    // バリデーションチェック
    if (!validationCheck()) {
      console.log("Validation Error");
      setLoading(false);
      return;
    }
    // データ取り出し
    const postForm = Object.keys(form).reduce((obj, key) => {
      obj[key] = form[key].value;
      return obj;
    }, {});
    // プロフィール更新処理
    const userApi = new UserApi();
    // 投稿可能な形式に変換
    const postData = convertPrivateUserToUserUpdateSchema(postForm);
    userApi
      .updateUser(user_id, postData)
      .then(() => {
        // ユーザページに遷移
        navigate(ROUTES.APP.USER.HOME.replace(":user_id", user_id));
      })
      .catch((err) => {
        alert(
          "プロフィール情報の更新に失敗しました。時間をおいて再度お試しください。"
        );
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleDeleteUser = () => {
    const confirm = window.confirm(
      "アカウントを削除してもよろしいですか？この操作は取り消せません。レビューは匿名投稿として残ります。"
    );
    if (!confirm) return;
    setLoading(true);
    const userApi = new UserApi();
    userApi
      .deleteUser(user_id)
      .then(() => {
        // ログアウト
        Auth.deleteUser().then(() => {
          setLoading(false);
          navigate(ROUTES.APP.ROOT);
        });
      })
      .catch(() => {
        alert(
          "アカウントの削除に失敗しました。時間をおいて再度お試しください。"
        );
        setLoading(false);
      });
  };

  const onSelectChange = (e, { name, value }) => {
    switch (name) {
      case "community":
        value = value.trim().replace(/\s+/g, " ");
        handleFormChange(e, { name, value });
        setCommunityOptions([]);
        break;
      default:
        break;
    }
  };

  // 入力値が変化したときの処理
  const onSearchChange = (e, obj) => {
    const { name, searchQuery } = obj;
    const univId = form.univ_id.value;
    switch (name) {
      case "community":
        if (searchQuery.length === 0) {
          setCommunityOptions([]);
          setCommunityFetchLoading(false);
          break;
        }
        clearTimeout(timer.current);
        setCommunityFetchLoading(true);
        timer.current = setTimeout(() => {
          const communityApi = new CommunityApi();
          communityApi
            .getUnivCommunities(univId, searchQuery)
            .then((res) => {
              const currentValue = form.community.value;
              const options = res
                .map((community) => createOption(community.name))
                .filter((option) => option.value !== currentValue); // 現在の値と同じものはoptionが重複するので除外
              setCommunityOptions(options);
            })
            .finally(() => setCommunityFetchLoading(false));
        }, 1000); // 1秒間入力がなければAPIを叩く
        break;
      default:
        break;
    }
  };

  const hasGraduated = form.grade.value < currentYear - 5;

  return (
    <>
      <Helmet>
        <title>楽単らくだ</title>
        <meta
          name="description"
          content="「楽単らくだ」は、履修選択に悩める大学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
        />
        <meta name="robots" content="index" />
      </Helmet>
      <Navbar
        style={{ marginBottom: "24px" }}
        subtitle={"授業評価"}
        show_pusher={false}
        show_control={false}
        show_action={false}
      />
      <Container text>
        {user ? (
          <>
            <Header as="h2">プロフィール編集</Header>
            <Form loading={loading}>
              <Form.Input
                label="ユーザー名"
                name="user_name"
                value={form.user_name.value}
                placeholder="らくたろう"
                required
                onChange={handleFormChange}
              />
              <ErrorMessage message={form.user_name.errorMessage} />
              <Form.Field>
                <label>ユーザーID</label>
                <p style={{ color: "gray" }}>{form.screen_name.value}</p>
              </Form.Field>
              <ErrorMessage message={form.screen_name.errorMessage} />
              <Form.Field>
                <label>所属大学</label>
                <p style={{ color: "gray" }}>
                  {UNIV[form.univ_id.value] && UNIV[form.univ_id.value].name}
                </p>
              </Form.Field>
              <ErrorMessage message={form.univ_id.errorMessage} />
              <Form.Select
                label="学部・研究科"
                name="department"
                options={departmentOptions}
                value={form.department.value}
                placeholder="-"
                required
                onChange={handleFormChange}
              />
              <ErrorMessage message={form.department.errorMessage} />
              <Form.Select
                label="学科・専攻"
                name="major"
                options={getMajorOptions(
                  form.univ_id.value,
                  form.department.value
                )}
                value={form.major.value}
                placeholder="-"
                required
                disabled={form.department.value === ""}
                onChange={handleFormChange}
              />
              <ErrorMessage message={form.major.errorMessage} />
              <Form.Field>
                <Form.Select
                  label="現在の学年"
                  name="grade"
                  options={
                    hasGraduated
                      ? gradeOptions.concat({
                          key: "graduated",
                          value: "graduated",
                          text: "卒業済み",
                        })
                      : gradeOptions
                  }
                  value={hasGraduated ? "graduated" : form.grade.value}
                  placeholder="-"
                  disabled={hasGraduated}
                  required
                  onChange={handleFormChange}
                />
                <ErrorMessage message={form.grade.errorMessage} />
              </Form.Field>
              <Grid>
                <Grid.Column width={11}>
                  <Form.Select
                    label="アイコン"
                    name="icon_type"
                    options={iconOptions}
                    value={form.icon_type.value}
                    onChange={handleFormChange}
                  />
                  <ErrorMessage message={form.icon_type.errorMessage} />
                </Grid.Column>
                <Grid.Column width={5}>
                  <RakudaIcon
                    bg_color={
                      ICON_TYPES[form.icon_type.value] &&
                      ICON_TYPES[form.icon_type.value].color
                    }
                    icon
                    circular
                    style={{
                      paddingTop: "10px",
                      maxWidth: "150px",
                    }}
                  />
                </Grid.Column>
              </Grid>
              <Form.TextArea
                label="自己紹介"
                name="introduction"
                value={form.introduction.value}
                placeholder="自己紹介を入力"
                onChange={handleFormChange}
              />
              <ErrorMessage message={form.introduction.errorMessage} />
              <Form.Field>
                <label>所属サークル・部活</label>
                <Dropdown
                  label="所属サークル・部活"
                  name="community"
                  options={[
                    ...(form.community.value !== ""
                      ? [createOption(form.community.value)]
                      : []), // 現在の値が""の場合はoptionを追加しない
                    ...communityOptions,
                  ]}
                  value={form.community.value}
                  placeholder="野球サークルらくだーず"
                  loading={communityFetchLoading}
                  fluid
                  search
                  selection
                  clearable
                  scrolling
                  closeOnChange
                  allowAdditions
                  additionLabel="サークルまたは部活を追加: "
                  noResultsMessage="所属サークルまたは部活を入力してください"
                  icon={<Icon name="search" />}
                  onSearchChange={onSearchChange}
                  onChange={onSelectChange}
                />
                <ErrorMessage message={form.community.errorMessage} />
                <p
                  style={{
                    color: "gray",
                    fontSize: "0.9rem",
                    marginTop: "5px",
                  }}
                >
                  所属しているサークルや部活を入力してください
                </p>
              </Form.Field>
              <Form.Field>
                <label>スコア</label>
                <p>{form.score.value} pt</p>
              </Form.Field>
              <ErrorMessage message={form.score.errorMessage} />
              <Form.Select
                label="進路"
                name="planning_grad_school"
                options={careerPathOptions}
                value={form.planning_grad_school.value}
                placeholder="-"
                required
                onChange={handleFormChange}
              />
              <ErrorMessage message={form.planning_grad_school.errorMessage} />
              <Form.Field>
                <Checkbox
                  label="就職先が決まっている"
                  name="is_employed"
                  checked={form.is_employed.value}
                  onChange={handleCheckboxChange}
                />
                <ErrorMessage message={form.is_employed.errorMessage} />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="学生生活お役立ちメールを受け取る"
                  name="admit_mail"
                  checked={form.admit_mail.value}
                  onChange={handleCheckboxChange}
                />
                <ErrorMessage message={form.admit_mail.errorMessage} />
                <p style={{ color: "gray", fontSize: "0.9rem" }}>
                  楽単らくだから学生生活に役立つ情報を受け取ることができます
                </p>
              </Form.Field>
              <Header as="h3">アカウント削除</Header>
              <Button
                content="アカウントを削除する"
                color="red"
                onClick={handleDeleteUser}
              />
              <div style={{ margin: "30px 0px 40px", textAlign: "center" }}>
                <Button
                  primary
                  content="キャンセル"
                  onClick={() => {
                    if (
                      !hasChanged ||
                      window.confirm("編集内容を破棄してもよろしいですか？")
                    ) {
                      navigate(
                        ROUTES.APP.USER.HOME.replace(":user_id", user_id)
                      );
                    }
                  }}
                  style={{ marginRight: "20px" }}
                />
                <Button
                  primary
                  content="更新する"
                  disabled={!hasChanged}
                  onClick={handleSubmit}
                />
              </div>
            </Form>
          </>
        ) : (
          <LoadingContent />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default UserEditPageTemplate;

const ErrorMessage = ({ message }) => {
  return (
    message && <p style={{ color: "red", fontSize: "0.9rem" }}>{message}</p>
  );
};

import React from "react";

import { Container, Form } from "semantic-ui-react";

import Navbar from "../../organisms/Navbar";
import Footer from "../../organisms/Footer";
import Summary from "../../organisms/Summary";
import Searchbox from "../../molecules/Searchbox";

import * as REVIEW_OBJECT from "../../../constants/review_object";
import UNIV from "../../../constants/univ";

const ReviewTemplateView = ({
  univ_id = "nagoya_u",
  subtitle = "授業評価",
  control = (
    <Form>
      <Form.Field>
        <Searchbox.View />
      </Form.Field>
    </Form>
  ),
  title,
  review_list = REVIEW_OBJECT.RandomExampleList(10), // TODO: サンプル更新
  user_id = "abcdef",
  deletePost = (id) => {
    console.log("<ReviewTemplateView> deletePost: " + id);
  },
  loading = false,
  is_valid_univ_id = Object.keys(UNIV).includes(univ_id),
  limited = false,
  ...otherProps
}) => {
  if (is_valid_univ_id) {
    return (
      <div {...otherProps}>
        <Navbar
          style={{ marginBottom: "24px" }}
          univ_id={univ_id}
          subtitle={subtitle}
          show_pusher={false}
          show_control={true}
          control={control}
        />
        <Container text>
          <Summary
            title={title}
            review_list={review_list}
            user_id={user_id}
            univ_id={univ_id}
            deletePost={deletePost}
            loading={loading}
            limited={limited}
          />
        </Container>
        <Footer univ_id={univ_id} />
      </div>
    );
  } else {
    return <div>Not Found</div>;
  }
};

export default ReviewTemplateView;

import React from "react";
import {Auth} from "aws-amplify";
import {useAuthenticator} from "@aws-amplify/ui-react";

import {
  Segment,
  Menu,
  Container,
  Icon,
  Header,
  Dropdown,
} from "semantic-ui-react";
import {useMediaQuery} from "react-responsive";

import {getUnivColor} from "../../../constants/univ";
import * as ROUTES from "../../../constants/routes";
import Logo from "../../atoms/Logo";
import {useNavigate} from "react-router-dom";

const Navbar = (props) => {
  const {
    univ_id = "",
    show_pusher = true,
    show_control = false,
    show_action = true,
    control = <></>,
    subtitle = "授業評価",
    ...otherProps
  } = props;
  const navigate = useNavigate();

  const isMobile = useMediaQuery({maxWidth: 767});
  const pusher = (
    <>
      大学生による授業評価を、
      {isMobile && <br />}
      オープンに。
    </>
  );

  const {user, authStatus} = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);

  return (
    <div {...otherProps}>
      <Segment
        vertical
        inverted
        style={{
          margin: 0,
          padding: 0,
          paddingBottom: show_pusher || show_control ? "24px" : "0px",
          backgroundColor: getUnivColor(univ_id),
        }}
      >
        <Menu
          inverted
          borderless
          size="small"
          style={{
            margin: 0,
            borderRadius: 0,
            backgroundColor: getUnivColor(univ_id),
          }}
        >
          <Container>
            <Menu.Item
              as="a"
              className="logo"
              header
              onClick={() =>
                navigate(
                  univ_id
                    ? ROUTES.APP.HOME.replace(":univ_id", univ_id)
                    : ROUTES.LANDING
                )
              }
            >
              <Logo icon={false} subtitle={subtitle} />
            </Menu.Item>
            <Menu.Menu position="right">
              {univ_id && show_action && (
                <Menu.Item
                  as="a"
                  onClick={() =>
                    navigate(ROUTES.APP.POST.replace(":univ_id", univ_id))
                  }
                >
                  <Icon name="write" />
                  投稿
                </Menu.Item>
              )}
              {show_action && (
                <>
                  {authStatus === "authenticated" ? (
                    <>
                      <Dropdown
                        icon={<Icon name="bars" size="large" />}
                        className="link item"
                      >
                        <Dropdown.Menu>
                          {univ_id && (
                            <Dropdown.Item
                              text="ホーム"
                              icon="home"
                              onClick={() => {
                                navigate(
                                  ROUTES.APP.HOME.replace(":univ_id", univ_id)
                                );
                              }}
                            />
                          )}
                          <Dropdown.Item
                            text="マイページ"
                            icon="user"
                            onClick={() => {
                              navigate(
                                ROUTES.APP.USER.HOME.replace(
                                  ":user_id",
                                  user.attributes.sub
                                )
                              );
                            }}
                          />
                          <Dropdown.Item
                            text="プロフィール編集"
                            icon="edit"
                            onClick={() => {
                              navigate(
                                ROUTES.APP.USER.EDIT.replace(
                                  ":user_id",
                                  user.attributes.sub
                                )
                              );
                            }}
                          />
                          <Dropdown.Item
                            text="ご意見箱"
                            icon="inbox"
                            onClick={() => {
                              window.open(
                                "https://docs.google.com/forms/d/e/1FAIpQLSefN6vt8e1mXpnOrEusY6dQ4784Q3efITP2U554kWo6lz3y-w/viewform?usp=sf_link",
                                "_blank"
                              );
                            }}
                          />
                          <Dropdown.Item
                            text="ログアウト"
                            icon="sign-out"
                            onClick={() => {
                              Auth.signOut().then(() => {
                                window.location.href = ROUTES.LANDING;
                              });
                            }}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : (
                    <Menu.Item
                      as="a"
                      onClick={() => {
                        navigate(ROUTES.APP.AUTH);
                      }}
                    >
                      <Icon name="sign-in" />
                      ログイン
                    </Menu.Item>
                  )}
                </>
              )}
            </Menu.Menu>
          </Container>
        </Menu>
        {show_pusher && (
          <Header
            inverted
            as="h1"
            textAlign="center"
            style={{margin: "24px 0px"}}
          >
            {pusher}
          </Header>
        )}
      </Segment>
      {show_control && (
        <Container text>
          <Segment
            raised
            style={{
              marginTop: "-24px",
              marginBottom: "24px",
            }}
          >
            {control}
          </Segment>
        </Container>
      )}
    </div>
  );
};

export default Navbar;

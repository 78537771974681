import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";

import { Header } from "semantic-ui-react";
import Stat from "../../molecules/Stat/index";
import { FeedList } from "../../organisms/feeds/FeedList";

import * as CRITERION from "../../../constants/criterion";
import { getUnivColor } from "../../../constants/univ";
import { FeedMixer } from "../../../utils/FeedMixer";
import AdApi from "../../../api/v2/AdApi";
import SurveyApi from "../../../api/v2/SurveyApi";

const Summary = (props) => {
  const {
    title = "タイトル",
    review_list = [],
    user_id = "abcdef",
    univ_id = "",
    loading = false,
    limited = false,
    ...otherProps
  } = props;
  const feedMixer = new FeedMixer();
  const [currentUser, setCurrentUser] = useState(null);
  const [adFeeds, setAdFeeds] = useState([]);
  const [surveyFeeds, setSurveyFeeds] = useState([]);

  const computeStatData = (review_list) => {
    var stat_data = {};
    for (const criteria of Object.values(CRITERION)) {
      if (!criteria.key) continue;
      stat_data[criteria.key] = [];
      for (const criteria_item of Object.values(criteria.ITEM)) {
        stat_data[criteria.key].push({
          name: criteria_item.label,
          key: criteria_item.key,
          value: 0,
        });
      }
    }

    if (review_list) {
      for (const node of review_list) {
        for (const criteria of Object.values(CRITERION)) {
          if (!criteria.key) continue;
          for (const item_i in Object.values(criteria.ITEM)) {
            if (
              stat_data[criteria.key][item_i].key ===
              node.review.criterion[criteria.key]
            ) {
              stat_data[criteria.key][item_i].value += 1;
            }
          }
        }
      }
    }
    return stat_data;
  };

  useEffect(() => {
    const adApi = new AdApi();
    adApi
      .getAds()
      .then((ads) => {
        setAdFeeds(
          ads
            .filter(
              (ad) =>
                !ad.is_fixed &&
                (ad.limited_to.length === 0 || ad.limited_to.includes(univ_id))
            ) // 全体向けとその大学向けの広告を取得
            .map((ad) => ({
              type: "ad",
              data: {
                src: ad.image_url.url,
                alt: ad.event_label,
                href: ad.href,
                maxHeight: 80,
              },
            }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
    // ログインしていれば、アンケートを取得
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setCurrentUser(user);
        const surveyApi = new SurveyApi();
        surveyApi
          .getSurveys()
          .then((surveys) => {
            setSurveyFeeds(
              surveys
                .filter(
                  (survey) => !survey.univ_id || survey.univ_id === univ_id
                ) // 全大学向けのアンケート、またはその大学限定のアンケートなら表示
                .map((survey) => ({
                  type: "survey",
                  data: {
                    survey: survey,
                  },
                }))
            );
          })
          .catch((err) => console.log(err));
      })
      .catch(() => null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const reviewFeeds = review_list.map((item) => {
    return { type: "review", data: item };
  });
  const feeds = feedMixer.getMixedFeeds(
    { reviewFeeds, adFeeds, surveyFeeds },
    5
  );

  // ローディング表示
  if (loading) {
    return (
      <div {...otherProps}>
        <Header as="h1">
          <Header.Content>読み込み中</Header.Content>
        </Header>
        <Stat loading={loading} />
        <FeedList loading={loading} />
      </div>
    );
  }

  // レビューを表示
  if (review_list.length > 0) {
    // レビューが存在する場合
    return (
      <div {...otherProps}>
        <Header as="h1">
          <Header.Content>
            <span>{title}</span>
            <Header.Subheader>
              {review_list.length + "件を表示中"}
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Stat
          loading={loading}
          data={computeStatData(review_list)}
          style={{ marginBottom: "24px" }}
          fill_color={getUnivColor(univ_id)}
          limited={limited}
        />
        <FeedList
          feeds={feeds}
          user_id={user_id}
          loading={loading}
          limited={limited}
          clickable
          is_admin={
            currentUser?.signInUserSession.idToken.payload[
              "cognito:groups"
            ]?.includes("admin") ?? false
          }
        />
      </div>
    );
  } else {
    // レビューが存在しない場合
    return (
      <div {...otherProps}>
        <Header as="h1">
          <Header.Content>
            <span>授業評価が見つかりませんでした</span>
            <Header.Subheader>新規登録をお願いします。</Header.Subheader>
          </Header.Content>
        </Header>
      </div>
    );
  }
};

export default Summary;

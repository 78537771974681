import React, {useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import {Helmet} from "react-helmet";
import {Auth, I18n} from "aws-amplify";
import AWS from "aws-sdk";
import {Authenticator, translations} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import LandingTemplate from "../LandingPage";
import AppHomeTemplate from "../AppHomePage";
import ReviewTemplate from "../SearchResultPage";
import PageTemplate from "../PagePage";
import RegisterPageTemplate from "../RegisterPage/index";
import AuthPageTemplate from "../AuthPage/index";
import UserPageTemplate from "../UserPage";
import UserEditPageTemplate from "../UserEditPage/index";
import ReviewPageTemplate from "../ReviewPage/index";
import CourseRankingPageTemplate from "../RankingPage/Course/index";
import TimeTablePageTemplate from "../TimeTablePage/index";
import PostReview from "../../organisms/PostReview/index";
import MaintenancePageTemplate from "../MaintenancePage";
import AfterLoginAction from "../../atoms/AfterLoginAction/index";
// import ForceRedirect from "../../atoms/ForceRedirect/index";

import {gaInitialize} from "../../../api/GA";
import systemApi from "../../../api/v2/SystemApi";
import * as ROUTES from "../../../constants/routes";
import {vocabularies} from "../../../constants/translations";
import config from "../../../api/aws-exports";

import "./style.less";

// AWS Settings
Auth.configure(config);
I18n.putVocabularies(translations);
I18n.putVocabularies(vocabularies);
I18n.setLanguage("ja");

const identityPoolId = process.env.REACT_APP_COGNITO_IDPOOL_ID;
const region = process.env.REACT_APP_AWS_AUTH_REGION;
AWS.config.update({
  region: region,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: identityPoolId,
  }),
});

const App = () => {
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  const fetchSystemSettings = () => {
    systemApi
      .getSystemSettings()
      .then((settings) => {
        setMaintenanceMode(settings.is_maintenance_mode);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    gaInitialize();
    fetchSystemSettings();
  }, []);

  return (
    <div>
      <Helmet defaultTitle="楽単らくだ - 大学生の履修選択をラクに">
        <meta
          name="description"
          content="「楽単らくだ（らくたんらくだ）」は、履修選択に悩める大学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
        />
        <meta name="robots" content="index" />
      </Helmet>
      <Authenticator.Provider>
        <Router>
          {maintenanceMode ? (
            <MaintenancePageTemplate />
          ) : (
            <AfterLoginAction>
              <Routes>
                <Route
                  path={ROUTES.LANDING}
                  element={<LandingTemplate />}
                />
                <Route
                  path={ROUTES.APP.ROOT}
                  element={<Navigate to={ROUTES.LANDING} replace />}
                />

                <Route path={ROUTES.TERMS} element={<PageTemplate />} />
                <Route path={ROUTES.PRIVACY} element={<PageTemplate />} />
                <Route path={ROUTES.GUIDE} element={<PageTemplate />} />

                <Route
                  path={ROUTES.APP.REGISTER}
                  element={<RegisterPageTemplate />}
                />
                <Route
                  path={ROUTES.APP.AUTH}
                  element={<AuthPageTemplate />}
                />
                <Route
                  path={ROUTES.APP.USER.HOME}
                  element={<UserPageTemplate />}
                />
                <Route
                  path={ROUTES.APP.USER.EDIT}
                  element={<UserEditPageTemplate />}
                />
                <Route
                  path={ROUTES.APP.REVIEW.SINGLE}
                  element={<ReviewPageTemplate />}
                />
                <Route
                  path={ROUTES.APP.TIMETABLE.ROOT}
                  element={<TimeTablePageTemplate />}
                />

                <Route path={ROUTES.APP.POST} element={<PostReview />} />
                <Route
                  path={ROUTES.APP.REVIEW.SEARCH}
                  element={<ReviewTemplate />}
                />
                <Route
                  path={ROUTES.APP.RANKING.COURSE}
                  element={<CourseRankingPageTemplate />}
                />
                <Route
                  path={ROUTES.APP.HOME}
                  element={<AppHomeTemplate />}
                />

                <Route path="*" element={<PageTemplate />} />
              </Routes>
            </AfterLoginAction>
          )}
        </Router>
      </Authenticator.Provider>
    </div>
  );
};

export default App;

import React from "react";
import {useLocation, useNavigate} from "react-router-dom";

import Navbar from "../../organisms/Navbar/index";
import Footer from "../../organisms/Footer/index";

import * as ROUTES from "../../../constants/routes";

import Page from "./Page";

const PageTemplate = (props) => {
  const location = useLocation();

  const renderPage = () => {
    switch (location.pathname) {
      case ROUTES.PRIVACY:
        return <Page.Privacy />;
      case ROUTES.TERMS:
        return <Page.Terms />;
      case ROUTES.GUIDE:
        return <Page.Guide />;
      case ROUTES.NOT_FOUND:
        return <Page.NotFound />;
      default:
        return <Page.NotFound />;
    }
  };

  console.log(location.pathname);

  return (
    <div {...props}>
      <Navbar
        style={{marginBottom: "24px"}}
        univ_id={""}
        subtitle={"授業評価"}
        show_pusher={false}
        show_control={false}
      />
      {renderPage()}
      <Footer hide_desert />
    </div>
  );
};

export default PageTemplate;

import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {Form} from "semantic-ui-react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Auth} from "aws-amplify";

import ReviewTemplateView from "./ReviewTemplateView";
import Searchbox from "../../molecules/Searchbox";

import UNIV from "../../../constants/univ";

import {gaEventSearch} from "../../../api/GA";
import ReviewApi from "../../../api/v2/ReviewApi";
import {convertReviewSchemaToReview} from "../../../api/v2/ApiUtils";

const ReviewTemplate = (props) => {
  const {univ_id = "nagoya_u"} = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [reviewList, setReviewList] = useState([]);
  const [params, setParams] = useState(getParams());
  const [title, setTitle] = useState("");
  const [userId, setUserId] = useState("abcdef");
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    setUserId(localStorage.getItem("user_session_id"));
    getReview();
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setCurrentUser(user);
      })
      .catch(() => null);
  }, []); // Run once on mount

  useEffect(() => {
    setParams(getParams());
    getReview();
  }, [location.search]); // Update when search params change

  function getParams() {
    const params = new URLSearchParams(location.search);
    return {
      keyword: params.get("keyword")?.split(",") || [],
      teacher: params.get("teacher")?.split(",") || [],
      course: params.get("course")?.split(",") || [],
      course_group: params.get("course_group")?.split(",") || [],
      semester: params.get("semester") || "",
    };
  }

  const getReview = () => {
    // TODO: title設定
    const {keyword, teacher, course, course_group, semester} = getParams();
    setLoading(true);

    // GAイベント送信
    const labels = [
      keyword.join(" "),
      teacher.join(" "),
      course.join(" "),
      course_group.join(" "),
      semester,
    ];
    gaEventSearch(labels.join(","));

    // 条件に一致するレビューを取得
    new ReviewApi()
      .getUnivReviews(univ_id, {
        keywords: keyword,
        teachers: teacher,
        course: course,
        course_group: course_group,
        semester: semester,
      })
      .then((reviews) => {
        setReviewList(
          reviews.map((review) => convertReviewSchemaToReview(review))
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isValidUnivId = Object.keys(UNIV).includes(univ_id);

  if (!isValidUnivId) {
    return <div>Not Found</div>;
  }

  return (
    <div {...props}>
      <Helmet>
        <title>楽単らくだ for {UNIV[univ_id].name}</title>
        <meta
          name="description"
          content="「楽単らくだ」は、履修選択に悩める大学生のための情報共有プラットフォームです"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <ReviewTemplateView
        univ_id={univ_id}
        subtitle={"for " + UNIV[univ_id].name}
        control={
          <Form>
            <Form.Field>
              <Searchbox
                univ_id={univ_id}
                location={location}
                pushHistory={navigate}
                showDetail
              />
            </Form.Field>
          </Form>
        }
        title={title}
        review_list={reviewList}
        user_id={currentUser ? currentUser.attributes.sub : "abcdef"}
        deletePost={() => {}} // deletePost logic can be added here
        loading={loading}
        limited={!currentUser}
      />
    </div>
  );
};

export default ReviewTemplate;

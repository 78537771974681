import React from "react";
import {
  Image,
  ListContent,
  ListHeader,
  ListItem,
  Icon,
} from "semantic-ui-react";

// TODO: 絶対パスに対応
// import RakudaIcon from "@/components/RakudaIcon";
// import { ICON_TYPES } from "@/constants/icon_types";
import RakudaIcon from "../../../atoms/RakudaIcon";
import { ICON_TYPES } from "../../../../constants/icon_types";

const RankingListItem = (props) => {
  const {
    index,
    user: { username: username, screenName: screenName, iconType: iconType },
    count = -1,
    onClick,
  } = props;

  return (
    <ListItem
      style={{
        display: "flex",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          style={{
            width: "30px",
            fontSize: "14px",
          }}
        >
          {index === 0 && <Icon name="chess queen" color="yellow" />}
          {index === 1 && <Icon name="chess queen" color="silver" />}
          {index === 2 && <Icon name="chess queen" color="brown" />}
          {index + 1 > 3 && index + 1}
        </span>
        <Image avatar>
          <RakudaIcon bg_color={ICON_TYPES[iconType]?.color} />
        </Image>
        <ListContent
          style={{
            overflow: "wrap",
            textAlign: "left",
            fontFamily: "sans-serif",
          }}
        >
          <ListHeader
            style={{
              fontWeight: "normal",
              color: "#333",
            }}
          >
            {username}
          </ListHeader>
          @{screenName}
        </ListContent>
      </div>
      <div
        style={{
          display: "inline-block",
          margin: "4px 8px",
          marginLeft: "auto",
          fontSize: "14px",
        }}
      >
        {count >= 10 && "🔥"} {count}件
      </div>
    </ListItem>
  );
};
export default RankingListItem;

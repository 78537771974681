import React, {useEffect, useState, useCallback} from "react";
import {Container, Header, Grid, Segment} from "semantic-ui-react";

import Navbar from "../../organisms/Navbar/index";
import Footer from "../../organisms/Footer/index";
import Landing from "../../organisms/Landing/index";

import UNIV from "../../../constants/univ";
import * as ROUTES from "../../../constants/routes";
import ReviewApi from "../../../api/v2/ReviewApi";
import ApplicantApi from "../../../api/v2/ApplicantApi";

import Logo from "../../atoms/Logo";
import {gaPageView} from "../../../api/GA";
import { useNavigate } from "react-router-dom";

const AppLink = ({u_key, status, user_count, ...otherProps}) => {
  const navigate = useNavigate();
  const [reviewCount, setReviewCount] = useState(-1);

  useEffect(() => {
    const getReviewCount = async (univ_id) => {
      const reviewApi = new ReviewApi();
      const count = await reviewApi.getUnivReviewsCount(univ_id);
      setReviewCount(parseInt(count));
    };

    if (status === "open") {
      getReviewCount(u_key);
    }
  }, [status, u_key]);

  return (
    <Segment
      onClick={() => navigate(ROUTES.APP.HOME.replace(":univ_id", u_key))}
      style={{display: "flex", alignItems: "center", cursor: "pointer"}}
      {...otherProps}
    >
      <Logo
        icon={true}
        bg_color={UNIV[u_key]?.color?.main}
        circular
        style={{width: "42px", marginRight: "8px"}}
      />
      <Header style={{margin: "0"}}>
        <span style={{fontSize: "16px"}}>{UNIV[u_key].name}</span>
        <Header.Subheader>
          {status === "open" &&
            (reviewCount > 0 ? (
              <div>
                <span style={{display: "inline-block"}}>
                  {reviewCount + "件の"}
                </span>
                <span style={{display: "inline-block"}}>評価</span>
              </div>
            ) : (
              ""
            ))}
          {status === "ready" &&
            (user_count > 0 ? (
              user_count >= 200 ? (
                <p style={{color: "#EE0000", fontWeight: "bold"}}>
                  近日公開予定
                </p>
              ) : (
                user_count + "件の評価"
              )
            ) : (
              ""
            ))}
        </Header.Subheader>
      </Header>
    </Segment>
  );
};

const LandingTemplate = ({...otherProps}) => {
  const [userCountObj, setUserCountObj] = useState({});

  useEffect(() => {
    const getUserCount = async () => {
      const applicantApi = new ApplicantApi();
      const res = await applicantApi.getEachApplicantsCount();
      setUserCountObj(res);
    };

    getUserCount();
    gaPageView("/");
  }, []);

  return (
    <div {...otherProps}>
      <Navbar
        style={{marginBottom: "24px"}}
        univ_id={""}
        subtitle={"授業評価"}
        show_pusher={true}
        show_control={false}
      />
      <Container text style={{marginBottom: 48}}>
        <Header as="h3" textAlign="center">
          大学一覧
        </Header>
        <Grid>
          {Object.keys(UNIV).map((u_key) => {
            if (UNIV[u_key] && UNIV[u_key].status === "open") {
              return (
                <Grid.Column key={u_key} computer={8} mobile={8}>
                  <AppLink
                    u_key={u_key}
                    status="open"
                  />
                </Grid.Column>
              );
            }
            return null;
          })}
        </Grid>
      </Container>
      <Landing />
      <Footer />
    </div>
  );
};

export default LandingTemplate;
